// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  Tooltip,
  Typography,
  withStyles,
  CircularProgress,
  TextField,
  Button,
} from '@material-ui/core';
import { DragAndDropUploader } from 'Components/DragAndDropUploader';
import { ActionsSnack } from 'Components/Snack';
import { Actions } from 'Components/Editor';
import type { TypeChiliAsset, TypeChiliIcon } from 'Components/Editor/Types';
import { truncateAssetFilename } from 'Utils/Utils';
// $FlowFixMe
import 'whatwg-fetch';

const { REACT_APP_API_URL } = process.env;
const MAX_FILENAME_LENGTH = 25;
const MAX_RETRIES = 10;

type Props = {
  classes: Object,
  variant: 'assets' | 'uploads' | 'icons' | 'uploads-from-path',
  folder: ?string,
  assets: {
    admins: ?(TypeChiliAsset[]),
    uploads: ?(TypeChiliAsset[]),
    predefined: ?(TypeChiliAsset[]),
    icons: ?(TypeChiliIcon[]),
  },
  dispatch: (action: { type: string }) => Promise<any>,
  onChange?: (asset: TypeChiliAsset | TypeChiliIcon) => void,
  mode: string,
};

type State = {
  pathValue: string,
  isUploading: boolean,
};

class AssetsList extends React.PureComponent<Props, State> {
  static defaultProps = {
    onChange: null,
  };

  retries = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      pathValue: '',
      isUploading: false,
    };
  }

  componentWillMount() {
    this.fetchAssets();
  }

  fetchAssets = (refetchUploads?: boolean) => {
    const { dispatch, variant, folder, assets } = this.props;

    switch (variant) {
      case 'icons':
        dispatch(Actions.getChiliIcons(folder));
        break;

      case 'uploads':
        if (assets.uploads === null || refetchUploads) {
          // fetch those only if we don't have them or there is a new upload
          dispatch(Actions.getChiliUploadedAssets());
        }
        break;

      case 'assets':
        if (assets.predefined === null) {
          // fetch those only if we don't have them

          dispatch(Actions.getChiliAssets());
        }
        break;

      case 'uploads-from-path':
        if (assets.admins === null || refetchUploads) {
          // fetch those only if we don't have them

          dispatch(Actions.getChiliAdminAssets());
        }
        break;

      default:
        break;
    }
  };

  handleChange = (asset: TypeChiliAsset | TypeChiliIcon) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(asset);
    }
  };

  onUploadComplete = () => {
    this.fetchAssets(true);
  };

  onUploadError = (errors: [{ [string]: string }]) => {
    const { dispatch } = this.props;

    let message = '';
    errors.map(e => {
      message += e.message;
      return null;
    });

    if (message && message.length > 0) {
      dispatch(ActionsSnack.show(message, undefined, 'error'));
    }
  };

  onPathValueChange = event => {
    const {
      target: { value },
    } = event;
    this.setState({ pathValue: value });
  };

  uploadFromPath = () => {
    this.setState({ isUploading: true });
    const { dispatch } = this.props;
    const { pathValue } = this.state;

    dispatch(
      Actions.uploadFromPath(
        pathValue,
        () => {
          this.setState({
            pathValue: '',
            isUploading: false,
          });
          dispatch(Actions.getChiliAdminAssets());
        },
        () => {
          this.setState({
            isUploading: false,
          });
        },
      ),
    );
  };

  render() {
    const { classes, assets, variant, mode } = this.props;
    const { pathValue, isUploading } = this.state;

    let data;
    switch (variant) {
      case 'icons':
        data = assets.icons;
        break;

      case 'uploads':
        data = assets.uploads;
        break;

      case 'assets':
        data = assets.predefined;
        break;

      case 'uploads-from-path':
        data = assets.admins;
        break;

      default:
        break;
    }

    return (
      <div className={classes.root}>
        <div className={classes.boxes}>
          {!data && <CircularProgress className={classes.loader} />}

          {data && data.length === 0 && (
            <Typography gutterBottom>
              <FormattedMessage
                id="AssetsList.message.empty"
                defaultMessage="No assets."
              />
            </Typography>
          )}

          {data &&
            data.map((item: TypeChiliAsset | TypeChiliIcon) => (
              <div
                role="presentation"
                key={item.id}
                onClick={() => this.handleChange(item)}
              >
                <Tooltip
                  title={truncateAssetFilename(item.name, MAX_FILENAME_LENGTH)}
                >
                  <figure
                    className={classes.preview}
                    style={{
                      backgroundColor:
                        mode === 'light' ? 'white' : 'transparent',
                    }}
                  >
                    <img
                      onError={event => {
                        const { currentTarget } = event;
                        if (!(item.previewUrl in this.retries)) {
                          this.retries[item.previewUrl] = 0;
                        }

                        if (this.retries[item.previewUrl] > MAX_RETRIES) {
                          return;
                        }

                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `${
                          currentTarget.src
                        }&random${new Date().getTime()}`;
                        this.retries[item.previewUrl] += 1;
                      }}
                      src={item.previewUrl}
                      alt=""
                    />
                  </figure>
                </Tooltip>
              </div>
            ))}
        </div>

        {variant === 'uploads' && (
          <DragAndDropUploader
            uploadUrl={`${REACT_APP_API_URL || ''}/chili/upload-image`}
            fieldName="customUpload"
            onUploadComplete={this.onUploadComplete}
            onError={this.onUploadError}
          />
        )}

        {variant === 'uploads-from-path' && (
          <div className={classes.uploadsFromPathWrapper}>
            {isUploading && <CircularProgress className={classes.loader} />}
            {!isUploading && (
              <>
                <TextField
                  label={
                    <FormattedMessage
                      id="AssetsList.upload.from.path.path"
                      defaultMessage="File Path"
                    />
                  }
                  fullWidth
                  disabled={isUploading}
                  value={pathValue}
                  onChange={this.onPathValueChange}
                />
                <Button
                  color="primary"
                  onClick={this.uploadFromPath}
                  disabled={isUploading}
                >
                  <FormattedMessage
                    id="AssetsList.upload.from.path.upload"
                    defaultMessage="Upload"
                  />
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column wrap',
  },
  boxes: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  loader: {
    margin: '10px auto',
  },
  preview: {
    overflow: 'hidden',
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    padding: 8,
    margin: 0,
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  uploadsFromPathWrapper: {
    display: 'flex',
  },
});

const mapStateToProps: (state: any) => any = state => ({
  assets: state.assets,
});

// $FlowFixMe
export default connect(mapStateToProps)(withStyles(styles)(AssetsList));
