// @flow

import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createFetch from 'Utils/createFetch';
import reducers from './reducers';

const { REACT_APP_BROWSER, REACT_APP_API_URL } = process.env;
if (!REACT_APP_API_URL) {
  console.error('REACT_APP_API_URL enviroment variable is missing!');
}

const apiMiddleware = fetch => store => next => action => {
  if (!action.meta || action.meta.type !== 'api') {
    return next(action);
  }

  // Find the request URL and compose request options from meta
  const { url } = action.meta;
  const fetchOptions = { ...action.meta };

  if (REACT_APP_BROWSER && action.meta.skipLoder === undefined) {
    store.dispatch({
      type: '@loader/show',
      payload: {
        default: true,
      },
    });
  }

  // Make the request
  return fetch(url, fetchOptions)
    .then(({ json }) => {
      if (action.type) {
        const newAction = Object.assign({}, action, {
          payload: json,
        });
        delete newAction.meta;
        store.dispatch(newAction);
      }

      if (typeof action.meta.onSuccess === 'function') {
        action.meta.onSuccess(json);
      }

      if (REACT_APP_BROWSER) {
        if (action.meta.skipSnack === undefined) {
          let title = 'Snack Title';
          let description = 'Snack Description';

          if (action.meta.snack) {
            title = action.meta.snack.title;
            description = action.meta.snack.description;
          }

          store.dispatch({
            type: '@snack/show',
            payload: {
              open: true,
              variant: 'success',
              data: {
                title,
                description,
              },
            },
          });
        }

        if (action.meta.skipLoder === undefined) {
          store.dispatch({
            type: '@loader/hide',
            payload: {
              default: false,
            },
          });
        }
      }
    })
    .catch(err => {
      if (REACT_APP_BROWSER) {
        store.dispatch({
          type: '@loader/hide',
          payload: {
            default: false,
          },
        });
      }

      if (err.json && err.json.errors) {
        store.dispatch({
          type: '@error/add',
          payload: err.json.errors,
        });
      }

      if (typeof action.meta.onError === 'function') {
        action.meta.onError(err.json ? err.json : err);
      }

      if (err.name === 'AbortError') {
        return null;
      }

      return console.error(err);
    });
};

const middleware = [
  apiMiddleware(createFetch(fetch, { baseUrl: REACT_APP_API_URL || '' })),
];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

if (process.env.ENV_MODE === 'dev' || process.env.ENV_MODE === 'development') {
  middleware.push(createLogger());
}

const store: any = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(...middleware)),
);
export default store;
