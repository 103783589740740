// @flow

import type { TypeAction, PreflightError } from './Actions';

export type TypeState = PreflightError;

export const DEFAULT_STATE: PreflightError = {
  isOpen: false,
  errorsChecked: 0,
  errorsCount: 0,
};

export default (state: TypeState = DEFAULT_STATE, action: TypeAction) => {
  switch (action.type) {
    case '@preflight/open':
      return { ...state, isOpen: !state.isOpen };

    case '@preflight/errorsChecked':
      return {
        ...state,
        errorsChecked: state.errorsChecked + action.payload,
      };

    case '@preflight/errorsCount':
      return {
        ...state,
        errorsCount: action.payload,
      };

    case '@preflight/resetErrorsChecked':
      return {
        ...state,
        errorsChecked: 0,
      };

    default:
      return state;
  }
};
