// @flow
import messagesEn from 'Translations/en.json';
import messagesNl from 'Translations/nl.json';

const getMessages = (locale: string) => {
  switch (locale) {
    case 'en':
      return messagesEn;
    case 'nl':
      return messagesNl;
    default:
      return messagesEn;
  }
};

export default getMessages;
