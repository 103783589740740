// @flow

// $FlowFixMe
import UniversalRouter from 'universal-router';
// $FlowFixMe
import generateUrls from 'universal-router/generateUrls';
import qs from 'qs';

import routes from 'Routes';

// TODO:
// - Improve types
// - Fix flow issues

type TypeRouter = any;
type TypeContext = {
  route: {
    load?: any,
    action?: any,
  },
};
type TypeParams = {
  locale: Object,
};

const router: TypeRouter = new UniversalRouter(routes, {
  resolveRoute(context: TypeContext, params: TypeParams) {
    const { locale } = params;
    // eslint-disable-next-line
    if (typeof context.route.load === 'function') {
      return context.route
        .load()
        .then(action => action.default(context, params))
        .then(
          data =>
            new Promise(resolve =>
              resolve({
                ...data,
                locale,
              }),
            ),
        );
    }
    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }
    return undefined;
  },
});

export const url = generateUrls(router, { stringifyQueryParams: qs.stringify });
export default router;
