// @flow

import * as React from 'react';

export type TypeVariant =
  | 'default'
  | 'success'
  | 'error'
  | 'warning'
  | 'info'
  | 'grey';
export type TypeData = {
  title: string,
  description: string,
  button: ?React.Node,
};

export type TypeSnack = {
  open: boolean,
  variant: TypeVariant,
  data: TypeData,
};

export type TypeActionShow = {
  type: '@snack/show',
  payload: TypeSnack,
};

export type TypeActionHide = {
  type: '@snack/reset',
};

export type TypeAction = TypeActionShow | TypeActionHide;

const Actions = {
  show: (
    title: string = '',
    description: string = '',
    variant: TypeVariant = 'default',
    button: ?React.Node = undefined,
  ) => ({
    type: '@snack/show',
    payload: {
      open: true,
      variant,
      data: {
        title,
        description,
        button,
      },
    },
  }),

  reset: () => ({
    type: '@snack/reset',
  }),
};

export default Actions;
