// @flow

import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  Box,
  Button,
  IconButton,
  Typography,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import {
  PhotoLibrary as IconPhotoLibrary,
  CloudUpload as IconCloudUpload,
  PictureInPicture as IconPictureInPicture,
  Clear as IconClear,
} from '@material-ui/icons';

import { ChiliAssetDialog } from 'Components/Editor';
import type { TypeChiliAsset } from 'Components/Editor/Types';
import { connect } from 'react-redux';
import { truncateAssetFilename } from 'Utils/Utils';
import { Roles } from 'Components/AA';

const MAX_FILENAME_LENGTH = 25;

type Props = {
  classes: Object,
  label: string,
  allowUpload: boolean,
  value?: string,
  folder?: string,
  disabled?: boolean,
  required?: boolean,
  debug?: boolean,
  onChange?: (assetId: string) => void,
  onClear?: () => void,
  intl: Object,
  dispatch: (action: any) => void,
  hideGallery: boolean,
  me: ?Object,
};

type State = {
  data: ?string,
  assetDialogOpen: boolean,
  uploadAssetDialogOpen: boolean,
};

class ChiliImage extends React.Component<Props, State> {
  static defaultProps = {
    value: '',
    debug: false,
    onChange: null,
    onClear: null,
    disabled: false,
    required: false,
    folder: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.value,
      assetDialogOpen: false,
      uploadAssetDialogOpen: false,
    };
  }

  handleDialogClose = (asset: ?TypeChiliAsset) => {
    const { onChange, dispatch } = this.props;
    const { data } = this.state;

    this.setState({
      assetDialogOpen: false,
      uploadAssetDialogOpen: false,
      data: asset ? asset.name : data,
    });

    if (onChange && asset) {
      onChange(asset.id);
    }

    dispatch({ type: '@chili/clear' });
  };

  handleAssetClear = () => {
    const { onChange, onClear, folder, required } = this.props;

    this.setState({
      data: '',
    });

    if (onChange && required) {
      onChange(folder ? 'productImage' : 'icon');
    }

    if (onClear && !required) {
      onClear();
    }
  };

  renderGalleryButton = () => {
    const { hideGallery, disabled, classes, intl } = this.props;

    if (hideGallery) {
      return null;
    }

    return (
      <Tooltip
        title={intl.formatMessage({
          id: 'ChiliImage.button.selectFromDirectory.tooltip',
          defaultMessage: 'Select from directory',
        })}
      >
        <div>
          <Button
            classes={{ root: classes.iconButton }}
            disabled={disabled}
            onClick={() =>
              this.setState({
                assetDialogOpen: true,
                uploadAssetDialogOpen: false,
              })
            }
            size="small"
          >
            <FormattedMessage
              id="ChiliImage.button.selectFromGallery"
              defaultMessage="Gallery"
            />
            <IconPhotoLibrary className={classes.rightIcon} />
          </Button>
        </div>
      </Tooltip>
    );
  };

  render() {
    const {
      classes,
      allowUpload,
      label,
      disabled,
      folder,
      intl,
      debug,
      me,
    } = this.props;
    const { assetDialogOpen, uploadAssetDialogOpen, data } = this.state;

    if (debug) {
      console.info(label, allowUpload, data, disabled, folder);
    }

    const fileName = truncateAssetFilename(data, MAX_FILENAME_LENGTH);

    const clearVariableValueButton = (
      <Tooltip
        title={intl.formatMessage({
          id: 'ChiliImage.button.clear.tooltip',
          defaultMessage: 'Upload custom',
        })}
      >
        <div>
          <IconButton
            disabled={disabled}
            onClick={this.handleAssetClear}
            className={classes.rightIcon}
          >
            <IconClear />
          </IconButton>
        </div>
      </Tooltip>
    );

    const isAdmin =
      me &&
      me.roles.some(role => [Roles.ADMIN, Roles.SUPER_ADMIN].includes(role));

    return (
      <div className={classes.root}>
        <Typography
          classes={{ root: classes.label }}
          variant="body2"
          color={disabled ? 'textSecondary' : 'inherit'}
        >
          {label}
        </Typography>

        <Box display="flex" alignItems="center" className={classes.box}>
          <Typography
            classes={{ root: classes.value }}
            variant="body2"
            color={disabled ? 'textSecondary' : 'inherit'}
          >
            {fileName}
          </Typography>
          {!folder ? (
            <React.Fragment>
              {!isAdmin && (
                <Tooltip
                  title={intl.formatMessage({
                    id: 'ChiliImage.button.uploadCustom.tooltip',
                    defaultMessage: 'Upload custom',
                  })}
                >
                  <div>
                    <Button
                      variant="text"
                      disabled={disabled}
                      onClick={() =>
                        this.setState({
                          assetDialogOpen: false,
                          uploadAssetDialogOpen: true,
                        })
                      }
                      classes={{ root: classes.iconButton }}
                      size="small"
                    >
                      <FormattedMessage
                        id="ChiliImage.button.uploadCustom"
                        defaultMessage="Upload"
                      />
                      <IconCloudUpload className={classes.rightIcon} />
                    </Button>
                  </div>
                </Tooltip>
              )}

              {this.renderGalleryButton()}
            </React.Fragment>
          ) : (
            <Tooltip
              title={intl.formatMessage({
                id: 'ChiliImage.button.selectFromDirectory.tooltip',
                defaultMessage: 'Select from directory',
              })}
            >
              <div>
                <Button
                  classes={{ root: classes.iconButton }}
                  disabled={disabled}
                  onClick={() =>
                    this.setState({
                      assetDialogOpen: true,
                      uploadAssetDialogOpen: false,
                    })
                  }
                  size="small"
                >
                  <FormattedMessage
                    id="ChiliImage.button.selectFromDirectory"
                    defaultMessage="Select icon"
                  />
                  <IconPictureInPicture className={classes.rightIcon} />
                </Button>
              </div>
            </Tooltip>
          )}

          {clearVariableValueButton}
        </Box>

        <ChiliAssetDialog
          open={assetDialogOpen}
          onClose={this.handleDialogClose}
          folder={folder}
        />

        <ChiliAssetDialog
          open={uploadAssetDialogOpen}
          onClose={this.handleDialogClose}
          folder={folder}
          allowUpload
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    padding: ` ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    alignItems: 'center',
  },
  box: {
    flex: '1 1 auto',
    padding: `${theme.spacing(1)}px 0`,
  },
  label: {
    paddingRight: theme.spacing(1),
    width: '25%',
    flexShrink: 0,
  },
  value: {
    flex: '1 1 auto',
  },
  iconButton: {
    textTransform: 'uppercase',
    fontWeight: 600,
    letterSpacing: '1px',
    borderRadius: 0,
    backgroundColor: '#e8e8e8',
    color: '#1e1e1e',
    marginLeft: theme.spacing(1),
    minWidth: 120,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

const mapStateToProps = state => ({
  me: state.me,
});

// $FlowFixMe
export default connect(mapStateToProps)(
  withStyles(styles)(injectIntl(ChiliImage)),
);
