// @flow

import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import getMessages from 'Translations';

import type { TypeStateMe } from 'Components/AA';

export type TypeIntlMessage = {
  id: ?string,
  defaultMessage: string,
};

type Props = {
  children: React.Node,
  title: ?TypeIntlMessage,
};

const IntlContext = (props: Props) => {
  const { children, title } = props;

  const me: TypeStateMe = useSelector((state: { [string]: any }) => state.me);
  const locale =
    me && me.company && me.company.locale ? me.company.locale : 'en';
  const messages = getMessages(locale);

  let documentTitle = 'MyDS';

  if (title && typeof title === 'object') {
    if ('id' in title && title.id && title.id in messages) {
      documentTitle = `${messages[title.id]} | ${documentTitle}`;
    } else if ('defaultMessage' in title) {
      documentTitle = `${title.defaultMessage} | ${documentTitle}`;
    }
  }

  document.title = documentTitle;

  return (
    <IntlProvider key={locale} locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default IntlContext;
