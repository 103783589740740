// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
  // $FlowFixMe
} from '@material-ui/core';
import { isInternetExlorer } from 'Utils/Utils';

const DialogInternetExlorer = () => {
  const [shouldDisplay, setShouldDisplay] = React.useState(isInternetExlorer());
  const handleClose = () => setShouldDisplay(false);

  return (
    <Dialog open={shouldDisplay} onClose={handleClose}>
      <DialogTitle>
        <FormattedMessage
          id="DialogInternetExplorer.title"
          defaultMessage="Internet explorer detected"
        />
      </DialogTitle>
      <DialogContent>
        <FormattedMessage
          id="DialogInternetExplorer.content"
          defaultMessage="We have detected a browser that might not fully support website's functionality. We recommend switching to another one."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          <FormattedMessage
            id="DialogInternetExplorer.button.continue"
            defaultMessage="Got it!"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = theme => ({
  message: {
    marginBottom: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.dark,
  },
  warning: {
    color: theme.palette.warning.dark,
  },
  ignore: {
    color: '#fbc02d',
  },
  messageType: {
    textTransform: 'capitalize',
  },
});

export default withStyles(styles)(DialogInternetExlorer);
