// @flow

import {
  debounce,
  getImageName,
  ALLOWED_PREFLIGHT_TYPES,
} from 'Components/Editor/Utils';

import type {
  TypeFrameCoordinates,
  TypeVariable,
  TypeVariableOption,
  TypeCropData,
  TypeChiliAsset,
  TypeChiliFrameObj,
  TypeSelectedFrame,
  TypeFrameConstraints,
} from './Types';

export default class EditorApi {
  editor: Object = {};

  debug: boolean = false;

  checkSelectedFrameTimer: ?IntervalID;

  checkDirtyTimer: ?IntervalID;

  constructor(editor: Object, debug?: boolean) {
    this.editor = editor;
    window.OnEditorEvent = this.OnEditorEvent;

    this.onZoomChanged = null;
    this.onLoad = null;
    this.onSelectedFrameChanged = null;
    this.onCalcFramesCommentsCoordinates = null;
    this.onHideFramesComments = null;
    this.onFrameImageDownloaded = null;

    if (debug) {
      this.debug = debug;
    }
  }

  onSelectedFrameChanged: ?(frame: ?TypeSelectedFrame) => void;

  onUpdateFrameCrop: ?(cropData: TypeCropData) => void;

  onLoad: ?(isFullyLoaded: boolean) => void;

  onZoomChanged: ?() => void;

  onHideFramesComments: ?() => void;

  onCalcFramesCommentsCoordinates: ?() => void;

  onFrameImageDownloaded: ?() => void;

  getDocument = () => {
    if (this.editor) {
      const documentObject = this.editor.GetObject('document');
      if (documentObject) {
        return documentObject;
      }
    }

    return null;
  };

  getDocumentFrameConstraints = () => {
    if (this.editor) {
      const documentFrameConstraints = this.editor.GetObject(
        'document.frameConstraints',
      );
      if (documentFrameConstraints) {
        return documentFrameConstraints;
      }
    }

    return null;
  };

  getDocumentZoom = () => {
    const doc = this.getDocument();
    return doc ? doc.zoom : null;
  };

  getIsDirty = (): ?boolean =>
    this.editor ? this.editor.GetDirtyState() : null;

  setZoomToFit = (next?: () => void) => {
    if (this.editor) {
      this.editor.ExecuteFunction('document.editor', 'Fit', 'page');

      if (next) {
        next();
      }

      if (this.onZoomChanged) {
        this.onZoomChanged();
      }
    }
  };

  setDocumentZoom = (zoom: number) => {
    if (this.editor) {
      this.editor.SetProperty('document', 'zoom', zoom);
    }
  };

  getDocumentPreflightResults = (types: string[] = ALLOWED_PREFLIGHT_TYPES) => {
    if (this.editor) {
      const preflightResults = this.editor.GetObject(
        'document.preflightResults',
      );

      if (preflightResults) {
        if (this.debug) {
          console.info('Document preflightResults', preflightResults);
        }
        const variables = this.getDocumentVariables();
        const parser = new DOMParser();
        const results: any[] = [];
        for (let i = 0; i < parseInt(preflightResults.length, 10); i += 1) {
          const result: any = this.editor.GetObject(
            `document.preflightResults[${i}]`,
          );
          result.variables = [];

          result.index = i;
          const id = this.extractIdFromSelector(result.frame, true);
          if (id) {
            result.frame = this.getFrameById(id);
            if ('variable' in result.frame && result.frame.variable !== null) {
              result.variables.push(
                this.editor.GetObject(result.frame.variable),
              );
            } else if (
              'textFlowXML' in result.frame &&
              result.frame.textFlowXML !== null
            ) {
              const textFlowDOM = parser.parseFromString(
                result.frame.textFlowXML,
                'text/xml',
              );

              const innerText = textFlowDOM.childNodes[0].textContent;
              if (innerText) {
                // $FlowFixMe
                const matches = [...innerText.matchAll(/%([A-Za-z0-9\s]+)%/g)];
                matches.forEach(match => {
                  let variable = null;
                  if (variables) {
                    variable = variables.find(
                      element => element.name === match[1],
                    );
                  }
                  result.variables.push(variable || { displayName: match[1] });
                });
              }
            }
          } else if (result.description.indexOf(':') > -1) {
            const variablesPart = result.description.substring(
              result.description.indexOf(':') + 1,
            );
            const matches = [...variablesPart.matchAll(/'(\S+)'/g)];
            matches.forEach(match => {
              let variable = null;
              if (variables) {
                variable = variables.find(element => element.name === match[1]);
              }
              result.variables.push(variable || { displayName: match[1] });
            });
          }
          if (types.includes(result.type)) {
            results.push(result);
          }
        }
        return results;
      }
    }

    return null;
  };

  getDocumentViewPrefs = () => {
    if (this.editor) {
      const viewPrefs = this.editor.GetObject('document.viewPreferences');
      if (viewPrefs) {
        if (this.debug) {
          console.info('Document view preferences', viewPrefs);
        }
        return viewPrefs;
      }
    }

    return null;
  };

  handleDocumentFullyLoaded = () => {
    if (this.debug) {
      console.info('handleDocumentFullyLoaded, adding listeners...');
    }

    if (this.editor) {
      this.editor.AddListener('ZoomAfterChange');
      this.editor.AddListener('SelectedFrameChanged');
      this.editor.AddListener('FrameRotated');
      this.editor.AddListener('VariableValueChanged');
      this.editor.AddListener('DocumentSaved');
      this.editor.AddListener('DocumentSaveRequested');
      this.editor.AddListener('PageCanvasScrollChanged');
      this.editor.AddListener('FrameMoveFinished');
      this.editor.AddListener('SelectionContentChanged');
      this.editor.AddListener('FrameImageDownloaded');

      if (this.onLoad) {
        this.onLoad(true);
      }

      // CHILI BUG: SelectedFrameChanged is not triggered when clicking outside a document after having selected text
      this.setCheckSelectedFrameInterval();

      if (this.debug) {
        const documentObject = this.editor.GetObject('document');
        console.info('documentObject', documentObject);
      }
    }
  };

  loadCropData = (assets: {
    uploads: TypeChiliAsset[],
    predefined: TypeChiliAsset[],
  }) => {
    const assetIds = [
      ...assets.predefined.map(asset => asset.id),
      ...assets.uploads.map(asset => asset.id),
    ];
    const allAssets = [...assets.predefined, ...assets.uploads];

    if (this.debug) {
      console.warn('loading crop data', assetIds, allAssets);
    }

    const allFrames = this.editor.GetObject('document.allFrames');
    if (allFrames) {
      for (let i = 0; i < allFrames.length; i += 1) {
        try {
          const frame = this.editor.GetObject(`document.allFrames[${i}]`);
          if (this.debug) {
            console.info(
              'checking frame',
              frame,
              assetIds.includes(frame.externalID),
            );
          }
          if (
            frame.externalID &&
            frame.fitMode === 'manual' &&
            assetIds.includes(frame.externalID)
          ) {
            const asset = allAssets.find(item => item.id === frame.externalID);
            if (asset) {
              this.setFrameCrop(frame, asset);
              if (this.debug) {
                console.warn('setting frame crop data', frame, asset);
              }
            }
          }
        } catch (error) {
          if (this.debug) {
            console.error(error);
          }
        }
      }
    }
  };

  setFrameCrop = (frame: TypeChiliFrameObj, asset: TypeChiliAsset) => {
    const notChiliProperties = ['id', 'frameId'];
    asset.positions
      .filter(position => position.frameId === frame.id)
      .forEach(position => {
        Object.keys(position)
          .filter(key => !notChiliProperties.includes(key))
          .forEach(prop => {
            if (this.debug) {
              const positionFrame = this.editor.GetObject(
                `document.allFrames[${position.frameId}]`,
              );
              console.info(
                'setting frame crop',
                position.frameId,
                prop,
                position[prop],
                positionFrame,
              );
            }
            this.editor.SetProperty(
              `document.allFrames[${position.frameId}]`,
              prop,
              position[prop],
            );
          });
      });
  };

  updateFrameCrop = () => {
    const frame = this.getSelectedFrameObject();
    if (frame && frame.externalID && this.onUpdateFrameCrop) {
      const cropData: TypeCropData = {
        chiliId: frame.externalID,
        frameId: frame.id,
        imgX: frame.imgX,
        imgY: frame.imgY,
        imgWidth: frame.imgWidth,
        imgHeight: frame.imgHeight,
        imgRotation: frame.imgRotation,
      };
      this.onUpdateFrameCrop(cropData);
    }
  };

  setCheckSelectedFrameInterval = () => {
    this.checkSelectedFrameTimer = setInterval(() => {
      this.checkSelectedFrame();
    }, 1000);
  };

  documentIsFullyLoaded = () =>
    this.editor &&
    this.editor.GetObject('document.readyForConsole').length === 0;

  waitForRealDocumentFullyLoaded = () => {
    let resolve: () => void;

    // $FlowFixMe
    const promise = new Promise(resolveFunc => {
      resolve = resolveFunc;
    });

    const check = () => {
      if (this.documentIsFullyLoaded()) {
        resolve();
      } else {
        setTimeout(() => {
          check();
        }, 100);
      }
    };

    check();

    return promise;
  };

  clearSelectedFrames = () => {
    if (this.editor) {
      this.editor.ExecuteFunction('document.selectedFrames', 'Clear');

      if (this.onSelectedFrameChanged) {
        this.onSelectedFrameChanged(null);
      }
    }
  };

  getDocumentXML = () => {
    if (this.editor) {
      return this.editor.ExecuteFunction('document', 'GetTempXML');
    }

    return null;
  };

  getSelectedFrameObject = () => {
    if (this.editor) {
      let frame = this.editor.GetObject('document.selectedFrame');
      if (!frame) {
        const selectedText = this.editor.GetObject('document.selectedText');
        if (selectedText) {
          frame = this.editor.GetObject('document.selectedText.frame');
        }
      }
      return frame;
    }
    return null;
  };

  deleteSelectedFrameObject = () => {
    if (this.editor) {
      let frameObj: ?TypeChiliFrameObj = this.getSelectedFrameObject();

      if (frameObj) {
        this.editor.ExecuteFunction('document.selectedFrame', 'Delete');
      } else {
        const selectedText = this.editor.GetObject('document.selectedText');
        if (selectedText) {
          frameObj = this.editor.GetObject('document.selectedText.frame');

          this.editor.ExecuteFunction('document.selectedText.frame', 'Delete');
        }
      }
    }
  };

  getFrameVariable = (frameId: string) => {
    return this.editor.GetObject(`document.allFrames[${frameId}].variable`);
  };

  handleSelectedFrameChanged = () => {
    if (this.editor) {
      const frame: ?TypeChiliFrameObj = this.getSelectedFrameObject();

      if (this.debug) {
        console.info('frame', frame);
      }

      let selectedFrame: ?TypeSelectedFrame = null;

      if (frame) {
        const frameConstraints: TypeFrameConstraints = this.editor.GetObject(
          `document.allFrames[${frame.id}].frameConstraints`,
        );

        selectedFrame = {
          id: frame.id,
          type: frame.type,
          label: `${frame.type} frame`,
          imgWidth: undefined,
          imgHeight: undefined,
          imgRotation: undefined,
          imageFlip: undefined,
          lockContent: frameConstraints.lockContent,
          fitMode: undefined,
        };

        if (frame.type === 'image') {
          if (frame.imgWidth) {
            selectedFrame.imgWidth = frame.imgWidth;
          }

          if (frame.imgHeight !== undefined) {
            selectedFrame.imgHeight = frame.imgHeight;
          }

          if (frame.imgRotation !== undefined) {
            selectedFrame.imgRotation = frame.imgRotation;
          }

          if (frame.imageFlip !== undefined) {
            selectedFrame.imageFlip = frame.imageFlip;
          }
          if (frame.fitMode !== undefined) {
            selectedFrame.fitMode = frame.fitMode;
          }
        }

        let variable;
        if (frame.variable) {
          variable = this.getFrameVariable(frame.id);
        }

        if (variable) {
          selectedFrame.label = variable.displayName;
        } else if (frame.type === 'text') {
          const text = this.editor.ExecuteFunction(
            `document.allFrames[${frame.id}]`,
            'GetText',
            false,
            false,
          );
          selectedFrame.label = text.replace(/%/g, '');
        }
      }

      if (this.onSelectedFrameChanged) {
        this.onSelectedFrameChanged(selectedFrame);
      }
    }
  };

  checkSelectedFrame = () => {
    const frameObj: ?TypeChiliFrameObj = this.getSelectedFrameObject();
    if (frameObj) {
      return;
    }

    // check for selected text frame
    const selectedText = this.editor.GetObject('document.selectedText');
    if (selectedText) {
      const selectedTextFrameObj = this.editor.GetObject(
        'document.selectedText.frame',
      );
      if (selectedTextFrameObj) {
        return;
      }
    }

    this.editor.ExecuteFunction('document.selectedFrames', 'Clear');
    if (this.onSelectedFrameChanged) {
      this.onSelectedFrameChanged(null);
      if (this.checkSelectedFrameTimer !== null) {
        clearInterval(this.checkSelectedFrameTimer);
        this.checkSelectedFrameTimer = null;
      }
    }
  };

  selectFrame = (frameId: string) => {
    this.editor.ExecuteFunction(`document.allFrames[${frameId}]`, 'Select');
  };

  getFrameById = (frameId: string) =>
    this.editor.GetObject(`document.allFrames[${frameId}]`);

  extractIdFromSelector = (selector: string, deep: ?boolean) => {
    if (!selector) {
      return undefined;
    }

    const lastId = deep === null ? false : deep;

    return lastId
      ? selector.slice(selector.lastIndexOf('[') + 1, selector.lastIndexOf(']'))
      : selector.slice(selector.indexOf('[') + 1, selector.indexOf(']'));
  };

  getFrameCoordinates = (frameId: string) => {
    if (this.editor) {
      try {
        this.editor.GetObject(
          `document.allFrames[${frameId}].contentFrameControl`,
        );
      } catch (error) {
        throw error;
      }

      const frameData = this.editor.GetObject(`document.allFrames[${frameId}]`);
      const coordinatesData = this.editor.ExecuteFunction(
        `document.allFrames[${frameId}].contentFrameControl`,
        'GetEditorCanvasMetrics',
      );

      /* CHILI Bug: after loading new Doc XML into the editor the y coordinate is not immediately set */
      if (coordinatesData.y === 'NaN') {
        throw new Error(`CoordinatesData.y is NaN on frame "${frameId}"`);
      }

      const frameCoordinates: TypeFrameCoordinates = {
        x: parseFloat(coordinatesData.x),
        y: parseFloat(coordinatesData.y),
        width: parseFloat(coordinatesData.width),
        height: parseFloat(coordinatesData.height),
        realWidth: parseFloat(frameData.realWidth),
        realHeight: parseFloat(frameData.realHeight),
      };

      return frameCoordinates;
    }

    return null;
  };

  // Document Variables
  setDocumentVariable = (nameOrId: string, value: any) => {
    if (this.editor) {
      this.editor.SetProperty(
        `document.variables[${nameOrId}]`,
        'value',
        value,
      );
    }
  };

  getDocumentVariableById = (id: string) => {
    if (this.editor) {
      const variable: any = this.editor.GetObject(`document.variables[${id}]`);
      if (!variable) return null;

      const myVar: TypeVariable = {
        id: variable.id,
        index: variable.index,
        name: variable.name,
        displayName: variable.displayName,
        value:
          variable.dataType !== 'image'
            ? variable.value
            : getImageName(variable.displayValue),
        displayValue: variable.displayValue,
        dataType: variable.dataType,
        allowCrop: variable.imageAllowCrop === 'true',
        allowCropManualCoordinates:
          variable.imageAllowCropManualCoordinates === 'true',
        options: variable.options,
        required: variable.required,
        visible: variable.visible,
        min: parseFloat(variable.minimum),
        max: parseFloat(variable.maximum),
        step: parseFloat(variable.stepSize),
        imagePulldownDirectory: variable.imagePulldownDirectory,
      };
      return myVar;
    }

    return null;
  };

  getDocumentVariables = () => {
    if (this.editor) {
      const numVariables = parseInt(
        this.editor.GetObject('document.variables').length,
        10,
      );
      const variables: TypeVariable[] = [];

      for (let i = 0; i < numVariables; i += 1) {
        const variable: any = this.editor.GetObject(`document.variables[${i}]`);

        variable.index = i;
        variable.options = null;
        if (variable.dataType === 'list') {
          const listItems = this.editor.GetObject(
            `document.variables[${variable.index}].listItems`,
          );

          const numItems = listItems.length;
          const options: TypeVariableOption[] = [];

          if (variable.includeEmptyItem === 'true') {
            options.push({
              value: '',
              label: '',
              optionalValue: '',
            });
          }
          for (let j = 0; j < numItems; j += 1) {
            const option = this.editor.GetObject(
              `document.variables[${variable.index}].listItems[${j}]`,
            );
            options.push({
              value: option.id,
              label: option.name,
              optionalValue: option.value,
            });
          }
          variable.options = options;
        }

        if (this.debug) {
          console.info('Adding chili variable to list: ', variable);
        }

        const myVar: TypeVariable = {
          id: variable.id,
          index: variable.index,
          name: variable.name,
          displayName: variable.displayName,
          value:
            variable.dataType !== 'image'
              ? variable.value
              : getImageName(variable.displayValue),
          displayValue: variable.displayValue,
          dataType: variable.dataType,
          allowCrop: variable.imageAllowCrop === 'true',
          allowCropManualCoordinates:
            variable.imageAllowCropManualCoordinates === 'true',
          options: variable.options,
          required: variable.required,
          visible: variable.visible,
          min: parseFloat(variable.minimum),
          max: parseFloat(variable.maximum),
          step: parseFloat(variable.stepSize),
          imagePulldownDirectory: variable.imagePulldownDirectory,
        };

        variables.push(myVar);
      }

      return variables;
    }

    return null;
  };

  getPageSnapshot = (
    page: number = 0,
    size: ?string = null,
    layers: ?(string[]) = null,
    frames: ?(string[]) = null,
    viewMode: 'preview' | 'edit' | 'technical' = 'preview',
    transparent: ?boolean = true,
  ) => {
    if (this.editor) {
      let snapshotSize = size;
      if (snapshotSize === null) {
        const doc = this.editor.GetObject('document');
        const pixelWidth = parseInt(doc.pixelWidth, 10);
        const pixelHeight = parseInt(doc.pixelHeight, 10);

        const pixels = Math.max(pixelWidth, pixelHeight);
        snapshotSize = `${pixels}x${pixels}`;
      }

      const snapshot = this.editor.GetPageSnapshot(
        page.toString(),
        snapshotSize,
        layers,
        frames,
        viewMode,
        transparent,
      );
      return snapshot;
    }

    return null;
  };

  OnEditorEvent = (type: string, targetID: string) => {
    if (this.debug) {
      console.info('OnEditorEvent type, targetID', type, targetID);
    }

    switch (type) {
      case 'DocumentFullyLoaded':
        if (this.onLoad) {
          this.onLoad(false);
        }
        this.waitForRealDocumentFullyLoaded().then(() => {
          this.setZoomToFit(() => {
            this.handleDocumentFullyLoaded();
          });
        });
        break;

      case 'ZoomAfterChange':
        if (this.onZoomChanged) {
          this.onZoomChanged();
        }
        break;

      case 'SelectedFrameChanged':
      case 'FrameRotated':
      case 'VariableValueChanged':
        this.handleSelectedFrameChanged();
        break;

      case 'FrameMoveFinished':
        if (this.onCalcFramesCommentsCoordinates) {
          this.onCalcFramesCommentsCoordinates();
        }
        break;

      case 'FrameMoveInProgress':
        if (this.onHideFramesComments) {
          this.onHideFramesComments();
        }
        break;

      case 'PageCanvasScrollChanged':
        if (this.onCalcFramesCommentsCoordinates) {
          debounce(this.onCalcFramesCommentsCoordinates, 1000)();
        }

        if (this.onHideFramesComments) {
          this.onHideFramesComments();
        }
        break;

      case 'FrameImageDownloaded':
        if (this.onFrameImageDownloaded) {
          this.onFrameImageDownloaded();
        }
        break;

      case 'SelectionContentChanged':
        debounce(this.updateFrameCrop, 1500)();
        break;

      default:
        break;
    }
  };

  // image toolbar
  setImageFrameFitMode = (fitMode: string) => {
    if (this.editor) {
      const frameObj: TypeChiliFrameObj = this.editor.GetObject(
        'document.selectedFrame',
      );

      if (frameObj && frameObj.type === 'image') {
        this.editor.SetProperty(
          `document.allFrames[${frameObj.id}]`,
          'fitMode',
          fitMode,
        );
        this.handleSelectedFrameChanged();
      }
    }
  };

  setImageFrameFitPoint = (fitPoint: string) => {
    if (this.editor) {
      const frameObj: TypeChiliFrameObj = this.editor.GetObject(
        'document.selectedFrame',
      );

      if (frameObj && frameObj.type === 'image') {
        this.editor.SetProperty(
          `document.allFrames[${frameObj.id}]`,
          'fitPoint',
          fitPoint,
        );
        this.handleSelectedFrameChanged();
      }
    }
  };

  centerImage = () => {
    if (this.editor) {
      const frameObj: TypeChiliFrameObj = this.editor.GetObject(
        'document.selectedFrame',
      );

      if (frameObj && frameObj.type === 'image') {
        const frameWidthInMillimeters = parseFloat(
          frameObj.width.replace('mm', ''),
        );
        const frameHeightInMillimeters = parseFloat(
          frameObj.height.replace('mm', ''),
        );
        const frameInsetLeftInMillimeters = parseFloat(
          frameObj.insetLeft.replace('mm', ''),
        );
        const frameInsetTopInMillimeters = parseFloat(
          frameObj.insetTop.replace('mm', ''),
        );
        const frameCenterX =
          frameInsetLeftInMillimeters + frameWidthInMillimeters * 0.5;
        const frameCenterY =
          frameInsetTopInMillimeters + frameHeightInMillimeters * 0.5;

        const imageWidthInMillimeters = frameObj.imgWidth
          ? parseFloat(frameObj.imgWidth.replace('mm', ''))
          : 0;
        const imageHeightInMillimeters = frameObj.imgHeight
          ? parseFloat(frameObj.imgHeight.replace('mm', ''))
          : 0;
        const imageX = frameCenterX - imageWidthInMillimeters * 0.5;
        const imageY = frameCenterY - imageHeightInMillimeters * 0.5;
        const imageXString = `${imageX} mm`;
        const imageYString = `${imageY} mm`;

        // manual fitMode is required to manipulate imgX and imgY
        this.editor.SetProperty(
          `document.allFrames[${frameObj.id}]`,
          'fitMode',
          'manual',
        );

        this.editor.SetProperty(
          `document.allFrames[${frameObj.id}]`,
          'imgX',
          imageXString,
        );
        this.editor.SetProperty(
          `document.allFrames[${frameObj.id}]`,
          'imgY',
          imageYString,
        );
        this.handleSelectedFrameChanged();
      }
    }
  };

  setImageFlip = (imageFlip: string) => {
    if (this.editor) {
      const frameObj: TypeChiliFrameObj = this.editor.GetObject(
        'document.selectedFrame',
      );

      if (frameObj && frameObj.type === 'image') {
        this.editor.SetProperty(
          `document.allFrames[${frameObj.id}]`,
          'imageFlip',
          imageFlip,
        );
        this.handleSelectedFrameChanged();
      }
    }
  };

  calcRotate = (
    cx: number,
    cy: number,
    x: number,
    y: number,
    angle: number,
    antiClockWise: boolean = false,
  ) => {
    if (angle === 0) {
      return { x: parseFloat(x), y: parseFloat(y) };
    }
    let radians;
    if (antiClockWise) {
      radians = (Math.PI / 180) * angle;
    } else {
      radians = (Math.PI / -180) * angle;
    }
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = cos * (x - cx) + sin * (y - cy) + cx;
    const ny = cos * (y - cy) - sin * (x - cx) + cy;
    return { x: nx, y: ny };
  };

  setImageRotation = (rotation: number) => {
    if (this.editor) {
      const frameObj: TypeChiliFrameObj = this.editor.GetObject(
        'document.selectedFrame',
      );

      if (frameObj && frameObj.type === 'image') {
        if (frameObj.fitMode !== 'manual') {
          this.setImageFrameFitMode('manual');
        }

        const { width, height, imgX, imgY, imgRotation } = frameObj;
        const prevRotation = parseFloat(imgRotation);
        const frameCx = parseFloat(width) / 2;
        const frameCy = parseFloat(height) / 2;
        const x = parseFloat(imgX);
        const y = parseFloat(imgY);

        const newCoordinates = this.calcRotate(
          frameCx,
          frameCy,
          x,
          y,
          rotation - prevRotation,
        );
        this.editor.SetProperty(
          `document.allFrames[${frameObj.id}]`,
          'imgRotation',
          rotation.toString(),
        );
        this.editor.SetProperty(
          `document.allFrames[${frameObj.id}]`,
          'imgX',
          newCoordinates.x.toString(),
        );
        this.editor.SetProperty(
          `document.allFrames[${frameObj.id}]`,
          'imgY',
          newCoordinates.y.toString(),
        );
        this.handleSelectedFrameChanged();
      }
    }
  };

  setImageWidth = (imgWidth: string) => {
    if (this.editor) {
      const frameObj: TypeChiliFrameObj = this.editor.GetObject(
        'document.selectedFrame',
      );

      if (frameObj && frameObj.type === 'image') {
        this.editor.SetProperty(
          `document.allFrames[${frameObj.id}]`,
          'imgWidth',
          imgWidth,
        );
        this.handleSelectedFrameChanged();
      }
    }
  };

  setImageHeight = (imgHeight: string) => {
    if (this.editor) {
      const frameObj: TypeChiliFrameObj = this.editor.GetObject(
        'document.selectedFrame',
      );

      if (frameObj && frameObj.type === 'image') {
        this.editor.SetProperty(
          `document.allFrames[${frameObj.id}]`,
          'imgHeight',
          imgHeight,
        );
        this.handleSelectedFrameChanged();
      }
    }
  };

  setAllowInlinePositioning = (allow: boolean = true) => {
    if (this.editor) {
      if (this.debug) {
        console.info('setAllowInlinePositioning', allow);
      }

      this.editor.SetProperty(
        'document.viewPreferences.frameHandlePreferences',
        'allowInlinePositioning',
        allow ? 'true' : 'false',
      );
    }
  };
}
