// @flow

import type { TypeAction, TypeMe } from './Actions';

export type TypeState = ?TypeMe;

export default (state: TypeState = null, action: TypeAction): TypeState => {
  switch (action.type) {
    case '@me/get':
    case '@me/set-company':
      return { ...state, ...action.payload };

    case '@me/get-companies':
      return {
        ...state,
        companies: action.payload,
      };

    case '@me/logout':
    case '@me/clear':
      return null;

    default:
      return state;
  }
};
