// @flow
/* eslint-disable no-restricted-globals */
import type { TypeValidator } from 'Components/Editor/Types';

export default class Validators {
  static required: TypeValidator = (value: string) => {
    let fieldValue = value;
    // In order for select, autocomplete and async-autocomplete to work it requires an object as a value.
    if (value instanceof Object) {
      // In order for async-autocomplete isMulti to work it requires an array as a value.
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return 'Field should not be empty';
        }
        return null;
      }
      fieldValue = '';
      if ('value' in value) {
        fieldValue = value.value.toString();
      }
    }

    if (!fieldValue || fieldValue.length === 0 || fieldValue.trim() === '') {
      return 'Field should not be empty';
    }
    return null;
  };
}
