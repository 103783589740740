// @flow

import { createBrowserHistory, createMemoryHistory } from 'history';

// Navigation manager, e.g. history.push('/home')
// https://github.com/mjackson/history
const History =
  process.env.REACT_APP_BROWSER === false
    ? createMemoryHistory()
    : createBrowserHistory();

export default History;
