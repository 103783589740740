/* @flow */

import Cookies from 'universal-cookie';

export type Fetch = (url: string, options?: any) => Promise<any>;

type Options = {
  baseUrl: string,
  cookie?: string,
  autorizationKey?: string,
};

let cookies = null;
const defaults = {
  credentials: 'include',
  method: 'GET', // handy with GraphQL backends
  baseUrl: '',
  mode: 'same-origin',
  headers: {
    'Content-Type': 'application/json',
  },
};
/**
 * Creates a wrapper function around the HTML5 Fetch API that provides
 * default arguments to fetch(...) and is intended to reduce the amount
 * of boilerplate code in the application.
 * https://developer.mozilla.org/docs/Web/API/Fetch_API/Using_Fetch
 */
function createFetch(
  fetch: Fetch,
  { baseUrl, cookie, autorizationKey }: Options,
) {
  if (cookie) {
    cookies = new Cookies(cookie);
  }
  defaults.baseUrl = baseUrl;
  defaults.mode = baseUrl ? 'cors' : 'same-origin';

  return async (url: string, options: Object = {}) => {
    if (!cookies) {
      cookies = new Cookies();
    }

    const headers = {};
    if (cookie) {
      headers.Cookie = cookie;
    }

    if (autorizationKey && cookies.get(autorizationKey)) {
      headers.Authorization = `${autorizationKey} ${cookies.get(
        autorizationKey,
      )}`;
    }

    let response = null;
    let json = {};
    let error = null;
    const URL =
      options.mode && options.mode === 'cors'
        ? url
        : `${defaults.baseUrl}${url}`;

    try {
      response = await fetch(URL, {
        ...defaults,
        ...options,
        headers:
          options && options.headers
            ? {
                ...options.headers,
                ...headers,
              }
            : {
                ...defaults.headers,
                ...headers,
              },
      });

      json = await response.json();
      if (response.status >= 400) {
        error = { response, json };
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.warn('Request aborted!');
      }

      error = err;
    }

    return new Promise((resolve, reject) => {
      if (!error) {
        resolve({ response, json });
      } else {
        reject(error);
      }
    });
  };
}

export default createFetch;
