// @flow

import * as React from 'react';
import { TextField, InputAdornment, withStyles } from '@material-ui/core';
import type { TypeInputEventHandler, TypeFieldMargin } from './Types';

type Props = {
  classes: Object,
  id: string,
  name: string,
  label?: string,
  placeholder?: string,
  value?: string,
  icon?: React.Node,
  type?: string,
  margin?: TypeFieldMargin,
  error?: boolean,
  required?: boolean,
  helperText?: string,
  disabled?: boolean,
  autocomplete?: boolean,
  onChange?: TypeInputEventHandler,
};

class BaseTextarea extends React.Component<Props> {
  static defaultProps = {
    value: '',
    label: undefined,
    placeholder: undefined,
    type: 'text',
    icon: null,
    margin: 'normal',
    error: undefined,
    required: false,
    helperText: undefined,
    disabled: false,
    autocomplete: true,
    onChange: undefined,
  };

  handleChange = (event: SyntheticInputEvent<*>) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const {
      classes,
      id,
      name,
      type,
      value,
      label,
      placeholder,
      icon,
      margin,
      error,
      required,
      helperText,
      disabled,
      autocomplete,
    } = this.props;

    return (
      <React.Fragment>
        <TextField
          multiline
          rows="4"
          rowsMax="4"
          id={id}
          type={type}
          name={name}
          value={value}
          label={label}
          classes={{
            root: classes.root,
          }}
          onChange={this.handleChange}
          margin={margin}
          InputProps={
            icon
              ? {
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment
                      variant="outlined"
                      position="start"
                      classes={{ root: classes.icon }}
                    >
                      {icon}
                    </InputAdornment>
                  ),
                }
              : { disableUnderline: true }
          }
          // eslint detecting different props as duplicate props because of the name
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={autocomplete ? undefined : { autoComplete: 'off' }}
          placeholder={placeholder}
          error={error}
          required={required}
          fullWidth
          helperText={helperText}
          disabled={disabled}
        />
      </React.Fragment>
    );
  }
}

const styles = () => ({
  root: {},
  icon: {
    '& svg': {
      fontSize: 18,
    },
  },
});

export default withStyles(styles)(BaseTextarea);
