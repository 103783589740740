// @flow

import type { TypeFormError } from './Types';

export const hasErrors = (fieldName: string, errors: ?(TypeFormError[])) => {
  let result = false;

  if (!errors) {
    return undefined;
  }

  errors.map((item: TypeFormError) => {
    if (item.key === fieldName) {
      result = true;
    }

    return item;
  });

  return result;
};

export const extractMessageFromErrors = (
  name: string,
  errors: ?(TypeFormError[]),
) => {
  let message = '';

  if (!errors) {
    return undefined;
  }

  errors.map((item: TypeFormError) => {
    if (item.key === name) {
      message += `${item.message} `;
    }

    return item;
  });

  return message;
};
