// @flow

import { STATUS } from 'Tools/Status';
import { ALLOWED_PREFLIGHT_TYPES } from 'Components/Editor/Utils';
import type {
  TypeAction,
  TypeSerie,
  TypeSerieItem,
  TypeMeta,
  TypeChiliEditorURL,
} from './Actions';

export type TypeState = {
  list: {
    result: TypeSerie[],
    meta: ?TypeMeta,
  },
  all: {
    result: TypeSerie[],
    meta: ?TypeMeta,
  },
  allShared: {
    result: TypeSerie[],
    meta: ?TypeMeta,
  },
  itemList: {
    result: TypeSerieItem[],
    meta: ?TypeMeta,
  },
  item: ?TypeSerieItem,
  editorUrl: ?TypeChiliEditorURL,
};

const DEFAULT_STATE = {
  list: {
    result: [],
    meta: null,
  },
  all: {
    result: [],
    meta: null,
  },
  allShared: {
    result: [],
    meta: null,
  },
  itemList: {
    result: [],
    meta: null,
  },
  item: null,
  editorUrl: null,
};

const normalizePreflightErrors = (item, types = ALLOWED_PREFLIGHT_TYPES) => {
  const preflightErrors = [];
  if (item.preflightResults && item.preflightResults.item) {
    // when we have 1 error we receive not array but object
    if (
      typeof item.preflightResults.item === 'object' &&
      !Array.isArray(item.preflightResults.item)
    ) {
      if (types.includes(item.preflightResults.item['@attributes'].type)) {
        preflightErrors.push(item.preflightResults.item['@attributes']);
      }

      return preflightErrors;
    }
    if (Array.isArray(item.preflightResults.item)) {
      item.preflightResults.item.forEach(error => {
        if (
          '@attributes' in error &&
          !types.includes(error['@attributes'].type)
        ) {
          return;
        }
        if (!error.variables || !('item' in error.variables)) {
          preflightErrors.push({
            ...error['@attributes'],
            frame:
              error.frames && error.frames.item
                ? error.frames.item['@attributes']
                : null,
            variable: null,
          });

          return;
        }
        if (!Array.isArray(error.variables.item)) {
          preflightErrors.push({
            ...error['@attributes'],
            frame:
              error.frames && error.frames.item
                ? error.frames.item['@attributes']
                : null,
            variable: error.variables.item['@attributes'],
          });

          return;
        }
        error.variables.item.forEach(variable => {
          preflightErrors.push({
            ...error['@attributes'],
            frame:
              error.frames && error.frames.item
                ? error.frames.item['@attributes']
                : null,
            variable: variable['@attributes'],
          });
        });
      });
    }
  }
  return preflightErrors;
};

export default (
  state: TypeState = DEFAULT_STATE,
  action: TypeAction,
): TypeState => {
  switch (action.type) {
    case '@series/list': {
      const { result } = action.payload;
      const { meta } = action.payload;
      return {
        ...state,
        list: {
          result,
          meta,
        },
      };
    }

    case '@series/all':
      return {
        ...state,
        all: {
          ...action.payload,
        },
      };

    case '@series/all-shared':
      return {
        ...state,
        allShared: {
          ...action.payload,
        },
      };

    case '@series/item-list': {
      const { result } = action.payload;
      const { meta } = action.payload;

      return {
        ...state,
        itemList: {
          result,
          meta,
        },
      };
    }

    case '@series/edit-item':
      return {
        ...state,
        itemList: {
          result: [
            ...state.itemList.result.map((i: TypeSerieItem) =>
              i.id === action.payload.id ? action.payload : i,
            ),
          ],
          meta: state.itemList.meta,
        },
      };

    case '@series/item':
    case '@series/save-item':
    case '@series/approve-item':
      return {
        ...state,
        item: {
          ...action.payload,
          preflightErrors: normalizePreflightErrors(action.payload),
        },
      };

    case '@chili/get-document-html-editor-url':
      return {
        ...state,
        editorUrl: action.payload,
      };

    case '@series/clear-item-list':
      return {
        ...state,
        itemList: {
          result: [],
          meta: null,
        },
      };

    case '@series/clear-item':
      return {
        ...state,
        item: null,
      };

    case '@series/approve': {
      const serie = action.payload;

      return {
        ...state,
        list: {
          result: state.list.result.map((s: TypeSerie) =>
            s.id === serie.id ? serie : s,
          ),
          meta: state.list.meta,
        },
        all: {
          result: state.all.result.map((s: TypeSerie) =>
            s.id === serie.id ? serie : s,
          ),
          meta: state.all.meta,
        },
        allShared: {
          result: state.allShared.result.map((s: TypeSerie) =>
            s.id === serie.id ? serie : s,
          ),
          meta: state.allShared.meta,
        },
      };
    }

    case '@series/clear-list':
      return {
        ...state,
        list: {
          result: [],
          meta: null,
        },
      };

    case '@comment/add':
      return state.item && state.item.status === STATUS.IN_REVIEW
        ? {
            ...state,
            item: {
              ...state.item,
              status: STATUS.FEEDBACK_PROVIDED,
            },
          }
        : state;
    case '@series/clear':
      return DEFAULT_STATE;

    default:
      return state;
  }
};
