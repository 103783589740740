// @flow

// TODO:
// - Improve History - this is related to cycle dependency when using History.
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

// The top-level (parent) route
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      name: 'home',
      path: '/',
      load: () => import('./Series/List'),
    },
    {
      name: 'series',
      path: '/series',
      children: [
        {
          name: 'seriesList',
          path: '',
          load: () => import('./Series/List'),
        },
        {
          name: 'seriesItemList',
          path: '/:id',
          load: () => import('./Series/ItemList'),
        },
        {
          name: 'seriesItemPreview',
          path: '/:id/preview/:itemId',
          load: () => import('./Series/ItemPreview'),
        },
        {
          name: 'seriesItemReview',
          path: '/:id/review/:itemId',
          load: () => import('./Series/ItemReview'),
        },
      ],
    },
    {
      name: 'maintenance',
      path: '/maintenance',
      load: () => import('./Maintenance'),
    },
    {
      name: 'login',
      path: '/login',
      load: () => import('./Login'),
    },
    {
      name: 'requestReset',
      path: '/request-new-password',
      load: () => import('./RequestPasswordReset'),
    },
    {
      name: 'resetPassword',
      path: '/reset-password/:token',
      load: () => import('./ResetPassword'),
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      name: '404',
      path: '(.*)',
      load: () => import('./NotFound'),
    },
  ],

  async action({ next }: Object) {
    // Execute each child route until one of them return the result
    const route = await next();
    return route;
  },
};

export default routes;
