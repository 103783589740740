// @flow

import * as React from 'react';
import { TextField, withStyles, Typography } from '@material-ui/core';

import type { TypeChange, TypeValidator } from 'Components/Editor/Types';
import { RenderCounter } from 'Components/RenderCounter';

type Props = {
  classes: Object,
  id: string,
  name: string,
  label: string,
  type?: string,
  value?: string,
  disabled?: boolean,
  margin?: 'none' | 'normal' | 'dense',
  debug?: boolean,
  required?: boolean,
  onChange?: TypeChange,
  validator?: TypeValidator,
  helperText?: ?string,
};

type State = {
  data: string,
  error: ?string,
};

class Textarea extends React.Component<Props, State> {
  static defaultProps = {
    value: '',
    margin: 'normal',
    debug: false,
    disabled: false,
    onChange: null,
    required: false,
    validator: undefined,
    helperText: undefined,
    type: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: props.value,
      error: null,
    };
  }

  handleChange = (name: string, validator: ?TypeValidator) => (
    event: SyntheticInputEvent<*>,
  ) => {
    const { onChange, type } = this.props;
    const { value } = event.target;

    let error = null;
    if (validator) {
      error = validator(event.target.value);
    }

    this.setState({
      data: value,
      error,
    });

    if (onChange) {
      onChange(name, value, type);
    }
  };

  render() {
    const {
      classes,
      id,
      name,
      label,
      margin,
      disabled,
      debug,
      required,
      validator,
      helperText,
    } = this.props;
    const { data, error } = this.state;

    return (
      <div className={classes.root}>
        <Typography
          classes={{ root: classes.label }}
          variant="body2"
          color={disabled ? 'textSecondary' : 'inherit'}
        >
          {label} {required ? ' *' : ''}
        </Typography>

        <TextField
          id={id}
          name={name}
          value={data}
          onChange={this.handleChange(name, validator)}
          disabled={disabled}
          required={required}
          error={Boolean(error)}
          helperText={error || helperText}
          margin={margin}
          multiline
          rows="4"
          fullWidth
        />

        <RenderCounter isActive={debug} />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    padding: ` ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    alignItems: 'center',
  },
  label: {
    paddingRight: theme.spacing(1),
    width: '25%',
    flexShrink: 0,
  },
});

export default withStyles(styles)(Textarea);
