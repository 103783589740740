// @flow

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import History from 'Tools/History';
import { url } from 'Tools/Router';
import Actions from './Actions';
import type { TypeState } from './Reducer';

const useAuthentication = () => {
  const dispatch = useDispatch();
  const me: TypeState = useSelector((state: { [string]: any }) => state.me);

  React.useEffect(() => {
    if (!me) {
      dispatch(
        Actions.get(undefined, () => {
          History.push(url('login'));
        }),
      );
    }
  });
  return me !== null;
};
export default useAuthentication;
