// @flow

import * as React from 'react';
import { TextField, InputAdornment, withStyles } from '@material-ui/core';
import type { TypeInputEventHandler, TypeFieldMargin } from './Types';

export type Props = {
  classes: Object,
  id: string,
  name: string,
  label?: string,
  placeholder?: string,
  value?: string,
  icon?: React.Node,
  iconEnd?: React.Node,
  inputProps?: Object,
  type?: string,
  margin?: TypeFieldMargin,
  error?: boolean,
  required?: boolean,
  helperText?: string,
  disabled?: boolean,
  autocomplete?: boolean,
  labelShrink?: boolean,
  inputRef?: (ref: any) => void,
  onChange?: TypeInputEventHandler,
  onKeyPress?: (event: SyntheticKeyboardEvent<*>) => void,
  onFocus?: (event: SyntheticKeyboardEvent<*>) => void,
  onBlur?: (event: SyntheticKeyboardEvent<*>) => void,
};

class BaseInput extends React.Component<Props> {
  static defaultProps = {
    value: '',
    label: undefined,
    placeholder: undefined,
    type: 'text',
    icon: null,
    iconEnd: null,
    inputProps: {},
    margin: 'normal',
    error: undefined,
    required: false,
    helperText: undefined,
    disabled: false,
    autocomplete: true,
    labelShrink: undefined,
    inputRef: undefined,
    onChange: undefined,
    onKeyPress: undefined,
    onFocus: undefined,
    onBlur: undefined,
  };

  handleChange = (event: SyntheticInputEvent<*>) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const {
      classes,
      id,
      name,
      type,
      value,
      label,
      placeholder,
      icon,
      iconEnd,
      inputProps,
      margin,
      error,
      required,
      helperText,
      disabled,
      autocomplete,
      labelShrink,
      inputRef,
      onKeyPress,
      onFocus,
      onBlur,
    } = this.props;
    const inputClasses = {
      root: classes.inputWrap,
      input: classes.input,
      focused: classes.focused,
      error: classes.error,
    };
    const inputNewProps = Object.assign(
      {},
      inputProps,
      autocomplete ? undefined : { autoComplete: 'off' },
    );

    const InputProps = { classes: inputClasses };
    let adorment = {};
    const adormentStart = {
      startAdornment: (
        <InputAdornment
          variant="filled"
          position="start"
          classes={{ root: classes.icon }}
        >
          {icon}
        </InputAdornment>
      ),
    };
    const adormentEnd = {
      endAdornment: (
        <InputAdornment
          variant="filled"
          position="end"
          classes={{ root: classes.icon }}
        >
          {iconEnd}
        </InputAdornment>
      ),
    };

    if (icon && !iconEnd) {
      adorment = adormentStart;
    }

    if (iconEnd && !icon) {
      adorment = adormentEnd;
    }

    if (icon && iconEnd) {
      adorment = {
        ...adormentStart,
        ...adormentEnd,
      };
    }

    return (
      <React.Fragment>
        <TextField
          id={id}
          type={type}
          name={name}
          value={value}
          label={label}
          classes={{
            root: classes.root,
          }}
          onChange={this.handleChange}
          onKeyPress={onKeyPress}
          margin={margin}
          InputProps={{
            ...InputProps,
            ...adorment,
          }}
          // eslint detecting different props as duplicate props because of the name
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={inputNewProps}
          InputLabelProps={{
            shrink: labelShrink,
          }}
          placeholder={placeholder}
          error={error}
          required={required}
          fullWidth
          helperText={helperText}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          inputRef={inputRef}
        />
      </React.Fragment>
    );
  }
}

const styles = () => ({
  root: {},
  inputWrap: {},
  input: {},
  focused: {},
  error: {},
  icon: {
    '& svg': {
      fontSize: 18,
      color: 'inherit',
    },
  },
});

export default withStyles(styles)(BaseInput);
