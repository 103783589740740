// @flow

import * as React from 'react';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { themeLight } from 'Tools/Theme';
// $FlowFixMe
import 'promise-polyfill/src/polyfill';
// $FlowFixMe
import 'whatwg-fetch';
// $FlowFixMe
import 'abortcontroller-polyfill';
import { IntlContext } from 'Components/IntlContext';
import type { TypeIntlMessage } from 'Components/IntlContext';
import { Snack } from 'Components/Snack';
import { DialogInternetExlorer } from 'Components/Dialogs';
import { ActionsSettings } from '../../Routes/Settings';

type Props = {
  children: React.Node,
  store: Object,
  title: ?TypeIntlMessage,
};

const App = (props: Props) => {
  const { children, store, title } = props;
  const { settings } = store.getState();
  if (settings.length === 0) {
    store.dispatch(ActionsSettings.getSettings());
  }

  return (
    <Provider store={store}>
      <IntlContext title={title}>
        <ThemeProvider theme={themeLight}>
          <CssBaseline />

          {children}
          <Snack />
          <DialogInternetExlorer />
        </ThemeProvider>
      </IntlContext>
    </Provider>
  );
};

export default App;
