// @flow

export type TypeSettingsEntry = {
  id: number,
  key: string,
  label: string,
  type: string,
  options: [],
  value: string | [],
  isMultiple: boolean,
  isVisible: boolean,
};

export type TypeSettings = TypeSettingsEntry[];

type TypeActionGet = {
  type: string,
  payload: TypeSettings,
};

export type TypeAction = TypeActionGet;

export type TypeApiError = {
  code: number,
  message: string,
  errors: Array<any>,
};

export type TypeResponseSuccess = (responseData: TypeSettings) => void;
export type TypeResponseError = (responseData: TypeApiError) => void;

const Actions = {
  getSettings: (
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@settings/get',
    meta: {
      type: 'api',
      method: 'GET',
      skipSnack: true,
      url: '/settings',
      onSuccess,
      onError,
    },
  }),
};

export default Actions;
