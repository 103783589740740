// @flow

// $FlowFixMe
import parser from 'fast-xml-parser';

const DISABLED_VARIABLE_NAMES = ['ARTIKELNUMMER'];

// Get reference from an input and value and trigger custom change event with them.
export const triggerChangeEvent = (elementRef: any, value: string) => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value',
    // $FlowFixMe
  ).set;

  if (nativeInputValueSetter) {
    nativeInputValueSetter.call(elementRef, value.toString());
  }

  const evt = new Event('input', { bubbles: true });

  if (elementRef) {
    elementRef.dispatchEvent(evt);
  }
};

export function debounce(func: () => void, delay: number = 100) {
  let timerId;
  return (...args: any) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => func(...args), delay);
  };
}

export function isVariableDisabled(name: string) {
  return DISABLED_VARIABLE_NAMES.indexOf(name) !== -1;
}

export function replaceArray(
  find: RegExp[],
  replace: string[],
  subject: string,
): string {
  let result = subject;
  for (let i = 0; i < find.length; i += 1) {
    result = result.replace(new RegExp(find[i]), replace[i]);
  }
  return result;
}

export function toTextFlow(value: string) {
  let newVal = '<TextFlow xmlns="http://ns.adobe.com/textLayout/2008">';
  const regExp = [/\^(.+?)\^/g, /~(.+?)~/g];
  const replace = [
    // replace all ocurrences between ^ and next ^ with <span baselineShift="superscript">*</span>
    '</span><span baselineShift="superscript">$1</span><span>',
    // replace all ocurrences between ~ and next ~ with <span baselineShift="subscript">*</span>
    '</span><span baselineShift="subscript">$1</span><span>',
  ];

  value.split('\n').forEach(val => {
    newVal += '<p><span>';
    newVal += replaceArray(regExp, replace, val);
    newVal += '</span></p>';
  });

  newVal += '</TextFlow>';

  return newVal;
}

function getSpanText(span: Object) {
  if (
    typeof span === 'string' ||
    span instanceof String ||
    typeof span === 'number'
  ) {
    return span;
  }

  if ('attr.baselineShift' in span && 'text' in span) {
    const prop = span['attr.baselineShift'];
    switch (prop) {
      case 'superscript':
        return `^${span.text}^`;

      case 'subscript':
        return `~${span.text}~`;

      default:
        return '';
    }
  }

  return '';
}

function getSpansFromParagraph(paragraph: Object) {
  let result = '';

  Object.keys(paragraph).forEach(key => {
    if (key === 'span') {
      // we found a span
      const spans = paragraph[key];
      if (Array.isArray(spans)) {
        spans.forEach(span => {
          result += getSpanText(span);
        });
      } else {
        result += getSpanText(spans);
      }
    }
  });

  return result;
}

export function formattedText(text: string) {
  let result = '';

  try {
    const textFlow = parser.parse(
      text,
      {
        ignoreAttributes: false,
        attributeNamePrefix: 'attr.',
        textNodeName: 'text',
        trimValues: false,
      },
      true,
    ).TextFlow;

    Object.keys(textFlow).forEach(key => {
      if (key === 'p') {
        // we found a paragraph
        if (Array.isArray(textFlow[key])) {
          textFlow[key].forEach(paragraph => {
            result += getSpansFromParagraph(paragraph);
            result += '\r\n';
          });
        } else {
          result += getSpansFromParagraph(textFlow[key]);
          result += '\r\n';
        }
      }
    });
  } catch (error) {
    console.warn('Error parsing formatted text: ', error);
  }

  if (result.length >= 2) {
    return result.substring(0, result.length - 2);
  }

  return result;
}

export function getImageName(imageXML: string) {
  let result = '';

  try {
    const itemObject = parser.parse(imageXML, {
      ignoreAttributes: false,
      attributeNamePrefix: 'attr.',
    }).item;

    if (itemObject && 'attr.name' in itemObject) {
      result = itemObject['attr.name'];
    }
  } catch (error) {
    console.warn('Error parsing imageXML: ', error);
  }

  return result;
}

export const INITIAL_ZOOM_PERCENTAGE = 70;

export const ALLOWED_PREFLIGHT_TYPES = ['warning', 'error'];
