// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Cookies from 'universal-cookie';
// $FlowFixMe
import _ from 'lodash';
import {
  CircularProgress,
  Typography,
  ButtonBase,
  Paper,
  withStyles,
} from '@material-ui/core';
import type {
  TypeVariable,
  TypeCropData,
  TypeChiliAsset,
  TypeChiliIcon,
  TypeSelectedFrame,
  TypeFrameWithComments,
} from 'Components/Editor/Types';
import VariablesForm from 'Components/Editor/VariablesForm';
import EditorApi from 'Components/Editor/EditorApi';
import { toTextFlow, INITIAL_ZOOM_PERCENTAGE } from 'Components/Editor/Utils';
import { Actions } from 'Components/Editor';
import { STATUS } from 'Tools/Status';

import srcComment from 'Assets/images/comment.svg';
import srcCommentTrans from 'Assets/images/commentTrans.svg';
import ImageToolbar from 'Components/Editor/ImageToolbar';

type TypeChiliUploadedImage = {
  id: number,
};

type Props = {
  classes: Object,
  editorUrl: string,
  apiKey: string,
  exportSettingsXML: string,
  documentXML: ?string,
  assets: {
    uploads?: TypeChiliUploadedImage[],
    predefined?: TypeChiliAsset[],
    icons?: TypeChiliIcon[],
    uploadsLoaded?: boolean,
    predefinedLoaded?: boolean,
    cropDataLoaded: boolean,
  },
  framesComments: { [string]: number },
  debug?: boolean,
  lightTheme: boolean,
  onZoomChange: (zoom: number, minZoom?: number, maxZoom?: number) => void,
  zoom: number,
  dispatch: (action: any) => void,
  editorRef?: (ref: any) => void,
  onUpdateFrameCrop?: (cropData: TypeCropData) => void,
  itemStatus: string,
  onSelectedFrameChanged?: (frame: ?TypeSelectedFrame) => void,
  onOpenComments?: () => void,
  isLoading: boolean,
  hideGallery: boolean,
  onDocumentFullyLoaded?: () => void,
};

type TypeEditorState = {
  dirty: boolean,
  variables: ?(TypeVariable[]),
  selectedFrame: ?TypeSelectedFrame,
};

type State = {
  xmlInjected: boolean,
  documentXMLresponse: string | null,
  fetchingDocumentXML: boolean,
  editor: TypeEditorState,
  isDoneLoading: boolean,
  isWaitingApiResponse: boolean,
  framesWithComments: TypeFrameWithComments[],
  isFullyLoaded: boolean,
};

const cookies = new Cookies();
const debugCookie = cookies.get('chili-debug');

class Editor extends React.PureComponent<Props, State> {
  iframe: HTMLIFrameElement | null = null;

  editorInstance: Object | null = null;

  editorApi: Object = {};

  static defaultProps = {
    debug: debugCookie ? debugCookie === 'true' : false,
    editorRef: null,
    onUpdateFrameCrop: null,
    onSelectedFrameChanged: null,
    onOpenComments: null,
    onDocumentFullyLoaded: null,
  };

  state = {
    xmlInjected: false,
    documentXMLresponse: null,
    fetchingDocumentXML: false,
    editor: {
      dirty: false,
      variables: null,
      selectedFrame: null,
    },
    isDoneLoading: false,
    isWaitingApiResponse: false,
    framesWithComments: [],
    isFullyLoaded: false,
  };

  constructor(props: Props) {
    super(props);

    const { editorRef } = this.props;
    if (editorRef) {
      editorRef(this);
    }
  }

  componentDidMount() {
    window.OnGetApiKey = this.OnGetApiKey;
    const { dispatch } = this.props;
    dispatch(Actions.getChiliAssets());
    dispatch(Actions.getChiliUploadedAssets());

    // Confirmation dialog before close of a window or a tab.
    // eslint-disable-next-line func-names
    window.onbeforeunload = function(event) {
      // Cancel the event as stated by the standard.
      event.preventDefault();

      // Chrome requires returnValue to be set.
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const { documentXML, debug, zoom, assets, dispatch } = nextProps;
    const {
      documentXML: oldDocumentXML,
      zoom: oldZoom,
      assets: oldAssets,
    } = this.props;

    if (debug) {
      console.info('componentWillReceiveProps');
      console.info('currentProps', this.props);
      console.info('nextProps', nextProps);
    }

    if (oldDocumentXML !== documentXML) {
      this.setState(
        {
          fetchingDocumentXML: false,
          xmlInjected: false,
          documentXMLresponse: null,
          editor: {
            dirty: false,
            variables: null,
            selectedFrame: null,
          },
        },
        () => this.injectDocumentXML(),
      );
    }

    if (zoom !== oldZoom && zoom > 0 && this.editorApi) {
      this.editorApi.setDocumentZoom(zoom);
    }

    if (!assets.predefinedLoaded && oldAssets.predefinedLoaded) {
      dispatch(Actions.getChiliAssets());
    }

    if (!assets.uploadsLoaded && oldAssets.uploadsLoaded) {
      dispatch(Actions.getChiliUploadedAssets());
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { framesComments } = this.props;
    const { editor } = this.state;
    if (
      (prevState.editor.selectedFrame && !editor.selectedFrame) ||
      (!prevState.editor.selectedFrame && editor.selectedFrame) ||
      (prevState.editor.selectedFrame &&
        editor.selectedFrame &&
        prevState.editor.selectedFrame.id !== editor.selectedFrame.id) ||
      !_.isEqual(prevProps.framesComments, framesComments)
    ) {
      this.calcFramesCommentsCoordinates();
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    this.editorApi.onZoomChanged = null;
    this.editorApi.onSelectedFrameChanged = null;
    this.editorApi.onUpdateFrameCrop = null;
    this.editorApi.onHideFramesComments = null;
    this.editorApi.onCalcFramesCommentsCoordinates = null;
    this.editorApi.onLoad = null;
    this.editorApi = {};
  }

  OnGetApiKey = callback => {
    const { apiKey } = this.props;
    if (apiKey) {
      callback(apiKey);
    }
  };

  handleZoomChange = () => {
    const documentZoom = this.editorApi.getDocumentZoom();
    const { debug, onZoomChange } = this.props;

    // set zoom
    if (documentZoom) {
      const { zoom } = this.props;
      const newZoom = parseInt(documentZoom, 10);

      if (zoom !== newZoom) {
        this.hideFramesComments();
        if (debug) {
          console.info('Setting zoom to', parseInt(newZoom, 10));
        }
        if (onZoomChange) {
          onZoomChange(newZoom);
        }
        this.calcFramesCommentsCoordinates();
      }
    }
  };

  zoomToFit = () => {
    if (this.editorApi) {
      this.editorApi.setZoomToFit();
    }
  };

  isDirty = () => {
    if (this.editorApi && this.editorApi.getIsDirty) {
      return this.editorApi.getIsDirty();
    }
    return false;
  };

  hideFramesComments = () => {
    this.setState({ framesWithComments: [] });
  };

  handleFrameChange = (frame: ?TypeSelectedFrame) => {
    const { onSelectedFrameChanged } = this.props;
    const { editor } = this.state;
    this.setState({
      editor: {
        ...editor,
        selectedFrame: frame,
      },
    });
    if (onSelectedFrameChanged) {
      onSelectedFrameChanged(frame);
    }
  };

  reloadCropData = () => {
    const { assets, dispatch } = this.props;
    if (!assets.cropDataLoaded) {
      this.editorApi.loadCropData(assets);
      dispatch(Actions.setCropDataLoaded(true));
    }
  };

  getDocumentXML = () => {
    if (this.editorApi && this.editorApi.getDocumentXML) {
      return this.editorApi.getDocumentXML();
    }
    return null;
  };

  iFrameLoaded = () => {
    const { debug, onUpdateFrameCrop } = this.props;

    if (this.iframe && this.iframe.contentWindow) {
      const { contentWindow } = this.iframe;

      if (!this.iframe.contentWindow.GetEditor) {
        return;
      }

      this.iframe.contentWindow.GetEditor(() => {
        const editor = contentWindow.editorObject;
        if (editor) {
          // instantiate the Editor API
          this.editorInstance = editor;
          this.editorApi = new EditorApi(editor, debug);
          if (debug) {
            window.editorApi = this.editorApi;
            window.editor = editor;
          }
          // register handlers here as well
          this.editorApi.onZoomChanged = this.handleZoomChange;
          this.editorApi.onUpdateFrameCrop = onUpdateFrameCrop;
          this.editorApi.onSelectedFrameChanged = this.handleFrameChange;
          this.editorApi.onHideFramesComments = this.hideFramesComments;
          this.editorApi.onFrameImageDownloaded = this.reloadCropData;
          this.editorApi.onCalcFramesCommentsCoordinates = this.calcFramesCommentsCoordinates;
          this.editorApi.onLoad = this.handleLoad;
          // inject document xml
          this.injectDocumentXML();
        }
      });
    }
  };

  handleLoad = (isFullyLoaded: boolean) => {
    if (!isFullyLoaded) {
      this.setState({
        isFullyLoaded,
        isDoneLoading: true,
        xmlInjected: true,
      });
      return;
    }

    const { editor } = this.state;
    const variables = this.editorApi.getDocumentVariables();

    this.setState({
      editor: {
        ...editor,
        variables,
      },
      isFullyLoaded,
    });

    const { debug, onZoomChange, itemStatus, assets, dispatch } = this.props;
    // set editor stuff in state
    const documentZoom = this.editorApi.getDocumentZoom();
    const viewPrefs = this.editorApi.getDocumentViewPrefs();
    if (debug) {
      console.info('handleLoad zoom', documentZoom);
      console.info('handleLoad viewPrefs', viewPrefs);
      console.info('handleLoad variables', variables);
    }

    if (onZoomChange) {
      onZoomChange(
        (documentZoom * INITIAL_ZOOM_PERCENTAGE) / 100,
        parseInt(viewPrefs.minZoom, 10),
        parseInt(viewPrefs.maxZoom, 10),
      );
    }

    if (
      this.editorApi &&
      [STATUS.DSP_REVIEW, STATUS.IN_REVIEW, STATUS.FEEDBACK_PROVIDED].includes(
        itemStatus,
      ) &&
      assets &&
      assets.predefinedLoaded &&
      assets.uploadsLoaded &&
      !assets.cropDataLoaded
    ) {
      if (debug) {
        console.info('Loading crop data...');
      }
      this.editorApi.loadCropData(assets);
      dispatch(Actions.setCropDataLoaded(true));
    }

    // DSP-381 lock image frames based on status
    const allowInlinePositioning = [
      STATUS.DSP_REVIEW,
      STATUS.IN_REVIEW,
      STATUS.FEEDBACK_PROVIDED,
    ].includes(itemStatus);
    this.editorApi.setAllowInlinePositioning(allowInlinePositioning);

    this.calcFramesCommentsCoordinates();
    this.setState({ isFullyLoaded });
    const { onDocumentFullyLoaded } = this.props;
    if (isFullyLoaded && onDocumentFullyLoaded) {
      onDocumentFullyLoaded();
    }
  };

  injectDocumentXML = () => {
    const { REACT_APP_DEFAULT_WORKSPACE_ID } = process.env;
    const { documentXML, debug } = this.props;
    const {
      xmlInjected,
      documentXMLresponse,
      fetchingDocumentXML,
    } = this.state;

    if (debug) {
      console.info(
        'injectDocumentXML',
        `xmlInjected: ${xmlInjected.toString()}`,
        `fetchingDocumentXML: ${fetchingDocumentXML.toString()}`,
      );
    }

    if (documentXML && !fetchingDocumentXML && !documentXMLresponse) {
      this.setState({ fetchingDocumentXML: true }, () => {
        fetch(documentXML, {
          method: 'GET',
          useNative: true,
        })
          .then(response => response.text())
          .then(responseText => {
            this.setState(
              {
                xmlInjected: false,
                fetchingDocumentXML: false,
                documentXMLresponse: responseText,
              },
              () => this.injectDocumentXML(),
            );
          })
          .catch(error => {
            if (debug) {
              console.info('error', error);
            }
          });
      });
    }

    if (!xmlInjected && documentXMLresponse) {
      if (this.editorInstance) {
        this.editorInstance.ExecuteFunction(
          'document',
          'OpenDocumentFromXml',
          documentXMLresponse,
          REACT_APP_DEFAULT_WORKSPACE_ID, // default ws id
        );
        if (debug) {
          console.info(
            'Inject document into Chili Editor - calling editor OpenDocumentFromXml...',
          );
        }
      }
    }
  };

  handleChange = (
    nameOrId: string,
    value: string | boolean | number,
    type?: string,
  ) => {
    const { debug } = this.props;

    let val = value;
    if (type && type === 'formattedtext') {
      val = toTextFlow(value.toString());
    }
    if (debug) {
      console.info('name', nameOrId);
      console.info('value', val);
    }

    if (nameOrId && nameOrId !== 'customUpload') {
      this.editorApi.setDocumentVariable(nameOrId, val);
    }
  };

  createPDF = () => {
    const { exportSettingsXML } = this.props;

    if (this.editorInstance) {
      this.editorInstance.ExecuteFunction(
        'document',
        'CreateTempPDF_FromXml',
        exportSettingsXML,
        '_blank',
      );
    }
  };

  calcFramesCommentsCoordinates = () => {
    const { framesComments } = this.props;
    const { editor } = this.state;
    const { selectedFrame } = editor;
    const framesWithComments = [];
    let addComment = !!selectedFrame;
    if (this.editorApi && this.editorApi.getFrameCoordinates) {
      Object.keys(framesComments).forEach(frameId => {
        const coordinates = this.editorApi.getFrameCoordinates(frameId);
        coordinates.x += coordinates.width + 6;
        // 32px (the width/height of the icon) + 6px distance from the frame handles = 38px
        coordinates.y =
          coordinates.y > 38 ? coordinates.y - 38 : coordinates.y + 38;
        framesWithComments.push({
          id: frameId,
          comments: framesComments[frameId],
          coordinates,
        });
        if (selectedFrame && selectedFrame.id === frameId) {
          addComment = false;
        }
      });

      if (selectedFrame && addComment) {
        const coordinates = this.editorApi.getFrameCoordinates(
          selectedFrame.id,
        );
        coordinates.x += coordinates.width + 6;
        coordinates.y =
          coordinates.y > 38 ? coordinates.y - 38 : coordinates.y + 38;
        framesWithComments.push({
          id: selectedFrame.id,
          comments: '+',
          coordinates,
        });
      }

      this.setState({ framesWithComments });
    }
  };

  handleCommentClick = (frameId: string) => {
    const { onOpenComments } = this.props;
    this.editorApi.selectFrame(frameId);
    if (onOpenComments) {
      onOpenComments();
    }
  };

  render() {
    const {
      itemStatus,
      editorUrl,
      lightTheme,
      classes,
      isLoading,
      debug,
      hideGallery,
    } = this.props;
    const {
      editor,
      isDoneLoading,
      xmlInjected,
      isWaitingApiResponse,
      framesWithComments,
      isFullyLoaded,
    } = this.state;

    const { selectedFrame } = editor;

    if (debug) {
      console.info('Editor render, editorUrl', editorUrl);
    }

    return (
      <div
        className={`${classes.root} ${
          isDoneLoading && !isLoading ? classes.isLoaded : ''
        }`}
      >
        {(!isDoneLoading || isLoading) && (
          <div className={classes.loader}>
            <Typography variant="h6" gutterBottom>
              <FormattedMessage
                id="Editor.loader.title"
                defaultMessage="Just A Few Seconds"
              />
            </Typography>

            <Typography variant="h6" gutterBottom>
              <strong>
                {!isDoneLoading && (
                  <FormattedMessage
                    id="Editor.loader.text"
                    defaultMessage="While We Fetch The Variables from the document"
                  />
                )}
              </strong>
            </Typography>

            <CircularProgress
              size={80}
              thickness={4}
              className={classes.loaderProgress}
            />
          </div>
        )}

        {isDoneLoading && !isLoading && (
          <aside
            className={`${classes.variablesForm} ${
              lightTheme
                ? classes.variablesFormLight
                : classes.variablesFormDark
            }`}
          >
            <VariablesForm
              loading={!xmlInjected || isWaitingApiResponse}
              onControlValueChange={this.handleChange}
              variables={editor.variables}
              itemStatus={itemStatus}
              hideGallery={hideGallery}
              debug={debug}
            />
          </aside>
        )}

        <div className={classes.viewContainer}>
          {isDoneLoading && !isFullyLoaded && (
            <div className={`${classes.loader} ${classes.editorLoader}`}>
              <Typography variant="h6" gutterBottom>
                <FormattedMessage
                  id="Editor.loader.title"
                  defaultMessage="Just A Few Seconds"
                />
              </Typography>

              <CircularProgress
                size={80}
                thickness={4}
                className={classes.loaderProgress}
              />
            </div>
          )}
          <iframe
            className={classes.view}
            ref={ref => {
              this.iframe = ref;
            }}
            onLoad={this.iFrameLoaded}
            title="HTML Editor iframe"
            src={editorUrl}
            frameBorder="0"
          />
          {isDoneLoading &&
            !isLoading &&
            framesWithComments.map(frame => (
              <ButtonBase
                key={frame.id}
                className={classes.frameCommentsButton}
                disableRipple
                style={{
                  top: frame.coordinates.y,
                  left: frame.coordinates.x,
                }}
                onClick={() => this.handleCommentClick(frame.id)}
              >
                <div
                  className={`${classes.frameComments}${
                    selectedFrame && selectedFrame.id === frame.id
                      ? ` ${classes.selected}`
                      : ''
                  }`}
                >
                  {frame.comments}
                </div>
              </ButtonBase>
            ))}
          {isDoneLoading && !isLoading && (
            <Paper className={classes.imageToolbar} elevation={4}>
              <ImageToolbar
                editorApi={this.editorApi}
                itemStatus={itemStatus}
                selectedFrame={selectedFrame}
                imageRotation={
                  selectedFrame && selectedFrame.imgRotation
                    ? parseFloat(selectedFrame.imgRotation)
                    : 0
                }
                imageWidth={
                  selectedFrame && selectedFrame.imgWidth
                    ? parseFloat(selectedFrame.imgWidth.replace('mm', ''))
                    : 0
                }
                imageHeight={
                  selectedFrame && selectedFrame.imgHeight
                    ? parseFloat(selectedFrame.imgHeight.replace('mm', ''))
                    : 0
                }
                imageFlip={
                  selectedFrame && selectedFrame.imageFlip
                    ? selectedFrame.imageFlip
                    : ''
                }
              />
            </Paper>
          )}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
    flexGrow: 1,
    minWidth: '600px',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.neutral.light,
    overflow: 'hidden',

    '&$isLoaded': {
      flexDirection: 'row',
    },

    '&$isLoaded $view': {
      opacity: 1,
      visibility: 'visible',
    },

    '&$isLoaded $variablesForm': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: 'all 0.4s',
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editorLoader: {
    backgroundColor: theme.palette.neutral.light,
    opacity: 0.8,
  },
  isLoaded: {},
  loaderProgress: {
    marginTop: 20,
  },
  variablesForm: {
    overflowX: 'auto',
    overflowY: 'scroll',
    flex: '0 0 50%',
    opacity: 0,
    paddingLeft: 58,
    visibility: 'hidden',
    transition: 'opacity 0.6s ease-in-out',
    zIndex: theme.zIndex.variablesForm,
  },
  variablesFormLight: {
    backgroundColor: theme.palette.neutral.light,
  },
  variablesFormDark: {
    backgroundColor: '#2f2f2f',
    color: '#fff',
  },
  viewContainer: {
    flex: '3 3 auto',
    position: 'relative',
    height: '100%',
  },
  view: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.neutral.main,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.6s ease-in-out',
  },
  showHelp: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  showHelpSwitch: {},
  frameCommentsButton: {
    position: 'absolute',
    zIndex: theme.zIndex.appBar,
  },
  frameComments: {
    width: 32,
    height: 32,
    lineHeight: '28px',
    textAlign: 'center',
    background: `url("${srcCommentTrans}") no-repeat`,
    backgroundSize: '100%',
    cursor: 'pointer',
    '&:hover': {
      background: `url("${srcComment}") no-repeat`,
      backgroundSize: '100%',
    },
    '&$selected': {
      background: `url("${srcComment}") no-repeat`,
      backgroundSize: '100%',
    },
  },
  selected: {},
  noLink: {
    textDecoration: 'none',
  },
  imageToolbar: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    paddingLeft: theme.spacing(1),
  },
});

const mapStateToProps: (state: any) => any = state => ({
  assets: state.assets,
  framesComments: state.comments.frames,
});

// $FlowFixMe
export default connect(mapStateToProps)(withStyles(styles)(Editor));
