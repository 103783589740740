// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  DoneAll as DoneAllIcon,
  Cached as CachedIcon,
  Close as IconClose,
} from '@material-ui/icons';
import {
  Paper,
  IconButton,
  withStyles,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import _ from 'lodash';
import type { TypeState } from './Reducer';
import Actions from './Actions';
import CollapseMenu from './CollapseMenu';

type Props = {
  classes: Object,
  preflightMessages: Array<Object>,
  getPreflightResults?: ?Function,
  refreshButtonVisible?: boolean,
};

const PreflightErrors = (props: Props) => {
  PreflightErrors.defaultProps = {
    refreshButtonVisible: true,
    getPreflightResults: null,
  };
  const {
    classes,
    preflightMessages,
    getPreflightResults,
    refreshButtonVisible,
  } = props;
  const dispatch = useDispatch();
  const isOpen: TypeState = useSelector(
    (state: { [string]: any }) => state.preflightErrors.isOpen,
  );

  const intl = useIntl();

  const getListItemPrimaryText = preflightMessageKey => {
    let values = {};
    const keyRegex = Array.from(
      // $FlowFixMe
      preflightMessageKey.matchAll(/([a-zA-Z]+)/g),
      match => match[0],
    );
    const key = keyRegex ? keyRegex.join('-') : '';

    if (key === 'Output-Resolution-too-low') {
      const regex = /\s(\d+)/.exec(preflightMessageKey);
      values = { dpi: regex ? regex[0] : '' };
    } else if (
      key === 'Image-resized-to-more-than' ||
      key === 'Image-resized-to-less-than'
    ) {
      const regex = /(\d+)%/.exec(preflightMessageKey);
      values = { percentage: regex ? regex[0] : '' };
    }

    return intl.formatMessage(
      {
        id: `Preflight.${key}`,
        defaultMessage: preflightMessageKey,
      },
      { ...values },
    );
  };

  const renderCollapseMenu = () => {
    const uniqueErrors = [];

    if (!Array.isArray(preflightMessages) || !preflightMessages.length) {
      return null;
    }

    try {
      preflightMessages.forEach(error => {
        const exists = uniqueErrors.find(
          modifiedError =>
            modifiedError.displayName === error.displayName &&
            (modifiedError.variable &&
              error.variable &&
              modifiedError.variable.displayName ===
                error.variable.displayName),
        );
        if (!exists) {
          uniqueErrors.push(
            error.description.indexOf(':') > -1
              ? {
                  ...error,
                  description: getListItemPrimaryText(
                    error.description.substring(
                      0,
                      error.description.indexOf(':'),
                    ),
                  ),
                  descriptionValue: error.description
                    .substring(error.description.indexOf(':') + 1)
                    .replaceAll("'", ''),
                }
              : {
                  ...error,
                  description: getListItemPrimaryText(
                    error.description.length > 0
                      ? error.description
                      : error.name,
                  ),
                },
          );
        }
      });

      const groupedPreflightErrors = _.groupBy(uniqueErrors, 'description');

      const keys = Object.keys(groupedPreflightErrors);
      let errorsCount = 0;
      keys.forEach(key => {
        errorsCount += groupedPreflightErrors[key].length;
      });
      dispatch(Actions.errorsCount(errorsCount));
      return keys.map(key => {
        return (
          <CollapseMenu
            key={`id_CollapseMenu_${key}`}
            preflightMessageErrorData={groupedPreflightErrors[key]}
            preflightMessageKey={key}
          />
        );
      });
    } catch (error) {
      console.info('Something went wrong: ', error);
      return null;
    }
  };
  const renderPopUpMenu = () => {
    return (
      <>
        <Box classes={{ root: classes.popUpMenuWrapper }}>
          <Box classes={{ root: classes.popUpMenuHeaderWrapper }}>
            <Typography classes={{ root: classes.popUpMenuHeader }}>
              <FormattedMessage
                id="Preflight.title"
                defaultMessage="Preflight checks"
              />
            </Typography>
            {refreshButtonVisible && (
              <IconButton
                classes={{ root: classes.popUpMenuHeaderIconButton }}
                onClick={() => {
                  if (getPreflightResults instanceof Function) {
                    getPreflightResults();
                  }
                }}
              >
                <CachedIcon />
              </IconButton>
            )}
          </Box>
          <Typography classes={{ root: classes.popUpMenuInfoParagraph }}>
            <FormattedMessage
              id="Preflight.description"
              defaultMessage="In order to Approve the item the following issues must be solved. Review and change the item, or ignore the issues by approving it with the checkboxes first."
            />
          </Typography>
        </Box>
        <Divider classes={{ root: classes.divider }} />
        <Box classes={{ root: classes.collapseMenuWrapper }}>
          {renderCollapseMenu()}
        </Box>
      </>
    );
  };

  return (
    <aside
      className={`${classes.aside} ${isOpen ? classes.open : classes.closed}`}
    >
      <Paper classes={{ root: classes.paper }} square>
        <div className={classes.content}>
          <>{renderPopUpMenu()}</>
        </div>

        <div className={classes.verticalBar}>
          <IconButton onClick={() => dispatch(Actions.open())}>
            {isOpen ? <IconClose /> : <DoneAllIcon />}
          </IconButton>

          <div className={classes.verticalTextPlaceholder}>
            <div className={classes.verticalText}>
              <FormattedMessage
                id="Preflight.verticalText"
                defaultMessage="PREFLIGHT CHECKS"
              />
            </div>
          </div>
        </div>
      </Paper>
    </aside>
  );
};

const styles = theme => ({
  aside: {
    position: 'fixed',
    top: 128,
    bottom: 64,
    width: '35%',
    zIndex: 999,
    transition: 'left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  open: {
    left: 0,
    zIndex: 1000,
  },
  closed: {
    left: 'calc(-35% + 58px)',
    height: 235,
    marginTop: '222px',
  },
  paper: {
    height: '100%',
    display: 'flex',
    color: theme.palette.text.secondary,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 'calc(35vw - 58px)',
  },
  verticalBar: {
    width: 58,
    backgroundColor: '#b4b4b4',
    textAlign: 'center',
    paddingTop: 6,
  },
  verticalTextPlaceholder: {
    position: 'relative',
    height: 140,
    marginTop: 6,
  },
  verticalText: {
    position: 'absolute',
    top: 190,
    width: 195,
    lineHeight: '58px',
    transform: 'rotate(-90deg)',
    transformOrigin: 'top left',
    textAlign: 'right',
  },
  popUpMenuWrapper: {
    padding: '20px',
  },
  popUpMenuHeader: {
    fontWeight: 'bold',
  },
  popUpMenuHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
  },
  popUpMenuHeaderIconButton: {
    width: '12px',
    height: '12px',
  },
  popUpMenuInfoParagraph: {
    fontSize: '0.8rem',
  },
  divider: {
    margin: '20px 0 0 0',
  },
  collapseMenuWrapper: {
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
});

export default withStyles(styles)(PreflightErrors);
