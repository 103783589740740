// @flow

import type { TypeFormError } from 'Components/FormHandler';
import type { TypeStatus } from 'Tools/Status';
import type { TypeActionAdd as TypeActionAddComment } from 'Components/Comments/Actions';

export type TypeChiliDocument = {
  id: number,
  chiliId: string,
  documentXML: string,
};

type TypeJWTTokenError = {
  expired?: boolean,
  not_found?: boolean,
  invalid?: boolean,
};

type TypeApiError = {
  code: number,
  message: string,
  jwt_token_error?: TypeJWTTokenError,
  formErrors: TypeFormError[],
};

export type TypeMeta = {
  limit: number,
  page: number,
  total: number,
  totalPages: number,
};

export type TypeCompany = {
  id: number,
  name: string,
  customerNumber: string,
  phone: string,
  configuration: {
    id: string,
    name: string,
  },
};

export type TypeTemplate = {
  id: number,
  name: string,
  chiliDocument: TypeChiliDocument,
};

export type TypeDigitalContent = {
  type: string,
  urls: {
    [string]: string,
  },
};

export type TypeSerieLog = {
  id: number,
  createdAt: string,
  updatedAt: string,
  itemsCount: number,
  erroredItemsCount: number,
  completedItemsCount: number,
};

export type TypeFinish = {
  id: number,
  name: string,
  displayName: string,
  additionalDisplayName: string,
  createdAt: string,
  updatedAt: string,
};

export type TypeSerie = {
  id: number,
  previewImageUrl: string,
  previewImageBackUrl: string,
  name: string,
  material: string,
  size: string,
  status: string,
  numberOfItems: number,
  pageCount?: number,
  company: TypeCompany,
  template: TypeTemplate,
  previewImage: ?TypeDigitalContent,
  previewImageTasksCount?: ?number,
  previewImageBack: ?TypeDigitalContent,
  previewImageBackTasksCount?: ?number,
  seriesLog?: TypeSerieLog[],
  brand?: string,
  concept: string,
  size?: string,
  articleCategory: string,
  productType: string,
  finish: TypeFinish,
  highResPdf: boolean,
  editMode: boolean,
  canBeApproved: boolean,
  canAddItems: boolean,
  canEditItems: boolean,
  canDeleteItems: boolean,
  hideGallery: boolean,
  isHidden: boolean,
  navision: boolean,
  rotateItemsBackPreview?: boolean,
};

export type TypeSerieItem = {
  id: number,
  previewImageUrl: string,
  hqPreviewImageUrl: ?string,
  previewImageBackUrl: string,
  hqPreviewImageBackUrl: ?string,
  nextItemPreviewUrl: ?string,
  nextItemPreviewBackUrl: ?string,
  prevItemPreviewUrl: ?string,
  prevItemPreviewBackUrl: ?string,
  previewImageTasksCount?: ?number,
  previewImageBackTasksCount?: ?number,
  articleNumber: string,
  productFamily: string,
  speciesName: string,
  status: TypeStatus,
  itemChiliDocument: TypeChiliDocument,
  indexInSerie: number,
  serieItemsCount: number,
  previousItemId: ?number,
  nextItemId: ?number,
  customerName: string,
  serieName: string,
  quantity: string,
  canSerieBeApproved?: ?boolean,
  pageCount?: number,
  preflightResults: Object,
  preflightErrors?: [],
};

type TypeChiliAssetPosition = {
  frameId: string,
  imgX: string,
  imgY: string,
  imgWidth: string,
  imgHeight: string,
  imgRotation: string,
};

export type TypeChiliAssetPayload = {
  chiliId: string,
  positions: TypeChiliAssetPosition[],
};

type TypeResponseError = (responseData: TypeApiError) => void;

type TypeResponseSuccess = (responseData: {
  result: TypeSerie[],
  meta: TypeMeta,
}) => void;

type TypeSingleResponseSuccess = (responseData: TypeSerie) => void;

export type TypeChiliEditorURL = {
  docID: string,
  exportSettingsXML: string,
  url: ?string,
  wsID: ?string,
  viewPrefsId: ?string,
  relativeURL: ?string,
  apiKey: ?string,
};

type TypeActionList = {
  type: '@series/list',
  payload: {
    result: TypeSerie[],
    meta: TypeMeta,
  },
};

type TypeActionAll = {
  type: '@series/all',
  payload: {
    result: TypeSerie[],
    meta: TypeMeta,
  },
};

type TypeActionAllShared = {
  type: '@series/all-shared',
  payload: {
    result: TypeSerie[],
    meta: TypeMeta,
  },
};

type TypeActionItemList = {
  type: '@series/item-list',
  payload: {
    result: TypeSerieItem[],
    meta: TypeMeta,
  },
};

type TypeActionItem = {
  type: '@series/item',
  payload: TypeSerieItem,
};

type TypeActionEditItem = {
  type: '@series/edit-item',
  payload: TypeSerieItem,
};

type TypeActionCreateItem = {
  type: '@series/create-item',
  payload: TypeSerieItem,
};

type TypeActionDuplicateItem = {
  type: '@series/duplicate-item',
  payload: TypeSerieItem,
};

type TypeActionDeleteItem = {
  type: '@series/delete-item',
  payload: { success: boolean },
};

type TypeActionSaveItem = {
  type: '@series/save-item',
  payload: TypeSerieItem,
};

type TypeActionApprove = {
  type: '@series/approve',
  payload: TypeSerie,
};

type TypeActionApproveItem = {
  type: '@series/approve-item',
  payload: TypeSerieItem,
};

type TypeActionChiliAssets = {
  type: '@series/chili-assets',
  payload: TypeChiliAssetPayload[],
};

type TypeActionClear = {
  type: '@series/clear',
};

type TypeActionClearList = {
  type: '@series/clear-list',
};

type TypeActionEditorUrl = {
  type: '@chili/get-document-html-editor-url',
  payload: TypeChiliEditorURL,
};

type TypeActionClearItem = {
  type: '@series/clear-item',
};

type TypeActionClearItemList = {
  type: '@series/clear-item-list',
};

type TypeActionChangeVisibility = {
  type: '@series/change-visibility',
  payload: { isHidden: boolean },
};

type TypeActionSerie = {
  type: '@series/serie',
  payload: TypeSerie,
};

export type TypeAction =
  | TypeActionList
  | TypeActionAll
  | TypeActionAllShared
  | TypeActionItemList
  | TypeActionItem
  | TypeActionEditItem
  | TypeActionCreateItem
  | TypeActionDuplicateItem
  | TypeActionDeleteItem
  | TypeActionSaveItem
  | TypeActionEditorUrl
  | TypeActionClear
  | TypeActionClearList
  | TypeActionClearItem
  | TypeActionClearItemList
  | TypeActionApprove
  | TypeActionApproveItem
  | TypeActionChiliAssets
  | TypeActionAddComment
  | TypeActionChangeVisibility
  | TypeActionSerie;

const Actions = {
  list: (
    page?: number = 1,
    limit?: number = 12,
    filter?: ?string = null,
    query?: ?string = null,
    folderId?: ?string = null,
    sharedOnly?: ?string = null,
    companyId?: ?string = null,
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
    signal?: ?Object = null,
  ) => {
    const params = new URLSearchParams();

    params.set('page', page.toString());
    params.set('limit', limit.toString());

    if (filter) {
      params.set('filter', filter);
    }
    if (query) {
      params.set('query', query);
    }
    if (folderId) {
      params.set('folderId', folderId);
    }
    if (sharedOnly) {
      params.set('sharedOnly', sharedOnly);
    }
    if (companyId) {
      params.set('companyId', companyId);
    }

    const paramsToString = params.toString();

    return {
      type: '@series/list',
      meta: {
        type: 'api',
        skipSnack: true,
        url: `/serie?${paramsToString}`,
        onSuccess,
        onError,
        signal,
      },
    };
  },

  all: (
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/all',
    meta: {
      type: 'api',
      skipSnack: true,
      url: '/serie?all=1',
      onSuccess,
      onError,
    },
  }),

  allShared: (
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/all-shared',
    meta: {
      type: 'api',
      skipSnack: true,
      url: '/serie?all=1&sharedOnly=1',
      onSuccess,
      onError,
    },
  }),

  itemList: (
    serieId: number,
    page?: number = 1,
    limit?: number = 12,
    filter?: ?string = null,
    query?: ?string = null,
    orderBy?: string = 'created',
    order?: string = 'asc',
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
    signal?: ?Object = null,
  ) => ({
    type: '@series/item-list',
    meta: {
      type: 'api',
      skipSnack: true,
      url: `/serie/${serieId}/item?page=${page}&limit=${limit}&filter=${filter ||
        ''}&query=${query || ''}&orderBy=${orderBy}&order=${order}`,
      onSuccess,
      onError,
      signal,
    },
  }),

  getItem: (
    serieId: number,
    serieItemId: number,
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/item',
    meta: {
      type: 'api',
      skipSnack: true,
      url: `/serie/${serieId}/item/${serieItemId}`,
      onSuccess,
      onError,
    },
  }),

  editItem: (
    serieId: number,
    serieItemId: number,
    data: { quantity: number },
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/edit-item',
    meta: {
      type: 'api',
      method: 'PUT',
      skipSnack: true,
      url: `/serie/${serieId}/item/${serieItemId}`,
      body: JSON.stringify(data),
      onSuccess,
      onError,
    },
  }),

  getEditorUrl: (
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@chili/get-document-html-editor-url',
    meta: {
      type: 'api',
      skipSnack: true,
      url: '/chili/get-document-html-editor-url',
      onSuccess,
      onError,
    },
  }),

  createItem: (
    serieId: number,
    data: { productFamily: string, speciesName: string },
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/create-item',
    meta: {
      type: 'api',
      method: 'POST',
      skipSnack: true,
      url: `/serie/${serieId}/item`,
      body: JSON.stringify(data),
      onSuccess,
      onError,
    },
  }),

  duplicateItem: (
    serieId: number,
    serieItemId: number,
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/duplicate-item',
    meta: {
      type: 'api',
      method: 'POST',
      skipSnack: true,
      url: `/serie/${serieId}/item/${serieItemId}`,
      onSuccess,
      onError,
    },
  }),

  deleteItem: (
    serieId: number,
    serieItemId: number,
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/delete-item',
    meta: {
      type: 'api',
      method: 'DELETE',
      skipSnack: true,
      url: `/serie/${serieId}/item/${serieItemId}`,
      onSuccess,
      onError,
    },
  }),

  saveItem: (
    serieId: number,
    serieItemId: number,
    approve: boolean = false,
    data: { documentXML: string },
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/save-item',
    meta: {
      type: 'api',
      method: 'POST',
      skipSnack: true,
      url: `/serie/${serieId}/item/${
        approve ? 'approve' : 'save'
      }/${serieItemId}`,
      body: JSON.stringify(data),
      onSuccess,
      onError,
    },
  }),

  approveItem: (
    serieId: number,
    serieItemId: number,
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/approve-item',
    meta: {
      type: 'api',
      method: 'POST',
      skipSnack: true,
      url: `/serie/${serieId}/item/approve/${serieItemId}`,
      onSuccess,
      onError,
    },
  }),

  approve: (
    serieId: number,
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/approve',
    meta: {
      type: 'api',
      method: 'POST',
      skipSnack: true,
      url: `/serie/${serieId}/approve`,
      onSuccess,
      onError,
    },
  }),

  changeVisibility: (
    serieId: number,
    data: { isHidden: boolean },
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/change-visibility',
    meta: {
      type: 'api',
      method: 'PATCH',
      skipSnack: true,
      url: `/serie/${serieId}/change-visibility`,
      body: JSON.stringify(data),
      onSuccess,
      onError,
    },
  }),

  updateChiliAssets: (
    data: TypeChiliAssetPayload[],
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@series/chili-assets',
    meta: {
      type: 'api',
      method: 'POST',
      skipSnack: true,
      url: `/chili/assets`,
      body: JSON.stringify(data),
      onSuccess,
      onError,
    },
  }),

  clearList: () => ({
    type: '@series/clear-list',
  }),

  clear: () => ({
    type: '@series/clear',
  }),

  getSerie: (
    serieId: number,
    onSuccess: ?TypeSingleResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ): Object => ({
    type: '@series/serie',
    meta: {
      type: 'api',
      skipSnack: true,
      url: `/serie/${serieId}`,
      onSuccess,
      onError,
    },
  }),
};

export default Actions;
