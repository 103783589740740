// @flow

export type TypeCompanyFolder = {
  id: number,
  itemsCount: number,
  name: String,
  parentId: ?number,
  seriesCount: number,
};

export type TypeMeta = {
  limit: number,
  page: number,
  total: number,
  totalPages: number,
};

type TypeResponseSuccess = TypeCompanyFolder[];

export type TypeApiError = {
  code: number,
  message: string,
};

type TypeActionGetCompanyFolders = {
  type: '@folders/get-company-folders',
  payload: TypeResponseSuccess,
  data: Object,
};

type TypeResponseError = (responseData: TypeApiError) => void;

export type TypeAction = TypeActionGetCompanyFolders;

const Actions = {
  getCompanyFolders: (
    folderId?: ?string = null,
    companyId?: ?string = null,
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => {
    const params = new URLSearchParams();

    if (folderId) {
      params.set('parentId', folderId);
    }
    if (companyId) {
      params.set('companyId', companyId);
    }

    const paramsToString = params.toString();

    return {
      type: '@folders/get-company-folders',
      data: {
        folderId,
      },
      meta: {
        type: 'api',
        method: 'GET',
        skipSnack: true,
        url: paramsToString
          ? `/company-folder?${paramsToString}`
          : '/company-folder',
        onSuccess,
        onError,
      },
    };
  },
};

export default Actions;
