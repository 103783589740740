export function objectToArray(obj) {
  const arr = [];

  Object.keys(obj).forEach(property => {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(obj[property]);

    arr.push(`${encodedKey}=${encodedValue}`);
  });

  return arr;
}

export function objectToParams(obj) {
  const arr = objectToArray(obj);
  const arrStringified = arr.join('&');

  return arrStringified;
}

export async function asyncForEach(array, callback) {
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const isLocationMatched = (location, exp) => {
  try {
    const currentRoute = location;
    let selected = false;

    if (!exp) {
      selected = false;
    } else {
      const regex = RegExp(exp);
      selected = regex.test(currentRoute);
    }
    return selected;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const requestAnimationFrame = callback => {
  let rafId = null;

  if (rafId) {
    window.cancelAnimationFrame(rafId);
  }

  rafId = window.requestAnimationFrame(() => {
    if (callback) {
      callback();
    }
  });
};

export const truncateText = (str, maxLen, separator = ' ') => {
  if (typeof str !== 'string' || str.length <= maxLen) return str;
  return `${str.substr(0, str.lastIndexOf(separator, maxLen))}...`;
};

export const generateUid = str =>
  `${str}${Math.random()
    .toString(36)
    .substr(2, 9)}`;

export function replaceArray(find, replace, subject) {
  let result = subject;
  for (let i = 0; i < find.length; i += 1) {
    result = result.replace(new RegExp(find[i]), replace[i]);
  }
  return result;
}

export function formatString(value) {
  let newVal = '';
  const regExp = [/\^(.+?)\^/g, /~(.+?)~/g];
  const replace = ['<sup>$1</sup>', '<sub>$1</sub>'];

  value.split('\n').forEach(val => {
    newVal += replaceArray(regExp, replace, val);
  });

  return newVal;
}

export function isInternetExlorer() {
  const isIeRegex = new RegExp(/(?:msie |trident.+?; rv:)(\d+)/i);
  return isIeRegex.test(navigator.userAgent) || !!document.documentMode;
}

export function truncateAssetFilename(fileName, maxLength) {
  if (fileName && fileName.length > maxLength) {
    return fileName.substring(fileName.length - maxLength);
  }

  return fileName;
}

export function getSeriesStateKey(query) {
  const sharedOnly = query && query.sharedOnly && query.sharedOnly === '1';

  return sharedOnly ? 'allShared' : 'all';
}
