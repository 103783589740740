// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
} from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';

type Props = {
  open: boolean,
  classes: Object,
  close: () => void,
  continueAnyway: () => void,
  saveItem: () => void,
};

const DialogNotSaved = (props: Props) => {
  const { open, close, continueAnyway, saveItem, classes } = props;

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle className={classes.title}>
        <FormattedMessage
          id="DialogNotSaved.title"
          defaultMessage="There are unsaved changes"
        />
        <IconButton
          aria-label="close"
          onClick={close}
          className={classes.closeButton}
        >
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          <FormattedMessage
            id="DialogNotSaved.text"
            defaultMessage="Would you like to approve the item before continuing?"
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={continueAnyway}
          color="secondary"
          autoFocus
          variant="contained"
        >
          <FormattedMessage
            id="DialogNotSaved.button.continue"
            defaultMessage="Continue anyway"
          />
        </Button>
        <Button onClick={saveItem} color="primary" variant="contained">
          <FormattedMessage
            id="DialogNotSaved.button.save"
            defaultMessage="Save & continue"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = theme => ({
  title: {
    margin: 0,
    padding: theme.spacing(2),
    paddingRight: '48px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export default withStyles(styles)(DialogNotSaved);
