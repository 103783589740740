// @flow
import type { TypeChiliAsset, TypeChiliIcon } from 'Components/Editor/Types';

export type TypeChiliUploads = {
  type: '@chili/get-uploaded-assets',
  payload: TypeChiliAsset[],
};

export type TypeChiliAdmins = {
  type: '@chili/get-admin-assets',
  payload: TypeChiliAsset[],
};

export type TypeChiliPredefined = {
  type: '@chili/get-assets',
  payload: TypeChiliAsset[],
};

export type TypeChiliIcons = {
  type: '@chili/get-icons',
  payload: TypeChiliIcon[],
};

export type TypeChiliCropDataLoaded = {
  type: '@chili/crop-data-loaded-in-editor',
  payload: boolean,
};

export type TypePredefinedDataLoaded = {
  type: '@chili/predefined-loaded',
  payload: boolean,
};

export type TypeUploadsLoaded = {
  type: '@chili/uploads-loaded',
  payload: boolean,
};

type TypeChiliClear = {
  type: '@chili/clear',
};

export type TypeAction =
  | TypeChiliUploads
  | TypeChiliAdmins
  | TypeChiliPredefined
  | TypeChiliIcons
  | TypeChiliCropDataLoaded
  | TypePredefinedDataLoaded
  | TypeUploadsLoaded
  | TypeChiliClear;

export default {
  uploadFromPath: (path: string, onSuccess: Function, onError: ?Function) => ({
    type: '@chili/upload-asset-from-path',
    meta: {
      type: 'api',
      url: `/chili/upload-image-from-path`,
      method: 'POST',
      skipSnack: true,
      body: JSON.stringify({ path }),
      onSuccess,
      onError,
    },
  }),
  getChiliUploadedAssets: () => ({
    type: '@chili/get-uploaded-assets',
    meta: {
      type: 'api',
      url: `/chili/get-assets?getCustomUploads=true`,
      method: 'GET',
      skipSnack: true,
    },
  }),

  getChiliAdminAssets: () => ({
    type: '@chili/get-admin-assets',
    meta: {
      type: 'api',
      url: `/chili/get-assets?getCustomUploads=true&admin=true`,
      method: 'GET',
      skipSnack: true,
    },
  }),

  getChiliAssets: () => ({
    type: '@chili/get-assets',
    meta: {
      type: 'api',
      url: `/chili/get-assets`,
      method: 'GET',
      skipSnack: true,
    },
  }),

  getChiliIcons: (folder: ?string) => ({
    type: '@chili/get-icons',
    meta: {
      type: 'api',
      url: `/chili/get-icons?folder=${folder || ''}`,
      method: 'GET',
      skipSnack: true,
    },
  }),

  setCropDataLoaded: (loaded: boolean) => ({
    type: '@chili/crop-data-loaded-in-editor',
    payload: loaded,
  }),

  setPredefinedLoaded: (loaded: boolean) => ({
    type: '@chili/predefined-loaded',
    payload: loaded,
  }),

  setUploadsLoaded: (loaded: boolean) => ({
    type: '@chili/uploads-loaded',
    payload: loaded,
  }),

  clear: () => ({
    type: '@chili/clear',
  }),
};
