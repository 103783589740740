// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CircularProgress, Typography, withStyles } from '@material-ui/core';
import {
  Input,
  Textarea,
  Select,
  ChiliImage,
  Validators,
} from 'Components/Editor/control';
import { isVariableDisabled, formattedText } from 'Components/Editor/Utils';

import type { TypeVariable } from 'Components/Editor/Types';
import { STATUS } from 'Tools/Status';
import { Actions as ActionsEditor } from 'Components/Editor';

const {
  REACT_APP_DEFAULT_ICON_ASSET_ID,
  REACT_APP_DEFAULT_PRODUCT_IMAGE_ASSET_ID,
} = process.env;

type Props = {
  classes: Object,
  onControlValueChange: (string, string, ?string) => void,
  itemStatus: string,
  loading?: boolean,
  variables: ?(TypeVariable[]),
  debug?: boolean,
  dispatch: (action: { type: string }) => Promise<any>,
  intl: Object,
  hideGallery: boolean,
};

class VariablesForm extends React.PureComponent<Props> {
  static defaultProps = {
    debug: false,
    loading: false,
  };

  renderVariablesForm = () => {
    const { variables, itemStatus } = this.props;

    if (!variables) return null;

    const enabled = [
      STATUS.DSP_REVIEW,
      STATUS.IN_REVIEW,
      STATUS.FEEDBACK_PROVIDED,
    ].includes(itemStatus);

    return variables.map((chiliVar: TypeVariable, index: number) =>
      this.renderChiliVariable(chiliVar, index, !enabled),
    );
  };

  handleAssetChange = (chiliVarId: string, assetId: string) => {
    const { onControlValueChange, dispatch } = this.props;

    if (assetId === 'productImage' || assetId === 'icon') {
      const placeholderAssetId =
        assetId === 'icon'
          ? REACT_APP_DEFAULT_ICON_ASSET_ID
          : REACT_APP_DEFAULT_PRODUCT_IMAGE_ASSET_ID;
      if (onControlValueChange && placeholderAssetId) {
        onControlValueChange(chiliVarId, placeholderAssetId);
      }
    }

    dispatch(ActionsEditor.setCropDataLoaded(false));
    if (onControlValueChange) {
      onControlValueChange(chiliVarId, assetId);
    }
  };

  handleAssetClear = (chiliVarId: string) => {
    const { onControlValueChange } = this.props;

    if (onControlValueChange) {
      onControlValueChange(chiliVarId, '');
    }
  };

  handleFormattedTextChange = (
    chiliVarId: string,
    value: string,
    type: string,
  ) => {
    const { onControlValueChange } = this.props;

    if (onControlValueChange) {
      onControlValueChange(chiliVarId, value, type);
    }
  };

  renderChiliVariable = (
    chiliVar: TypeVariable,
    index: number,
    disabled: boolean,
  ) => {
    const { onControlValueChange, intl, debug, hideGallery } = this.props;
    if (debug) {
      console.info('chiliVar', chiliVar);
    }

    if (chiliVar.name.charAt(0) === '_') {
      // DSP-234 do not render variables starting with underscore
      return null;
    }

    let field;
    const required = chiliVar.required === 'true';
    const visible = chiliVar.visible === 'true';
    switch (chiliVar.dataType) {
      case 'string':
        field = visible ? (
          <Input
            key={`var_${chiliVar.name}`}
            name={chiliVar.id}
            type="text"
            value={chiliVar.value}
            onChange={onControlValueChange}
            label={chiliVar.displayName}
            required={required}
            validator={required ? Validators.required : undefined}
            disabled={disabled || isVariableDisabled(chiliVar.name)}
            debug={debug}
          />
        ) : null;
        break;

      case 'text':
      case 'longtext':
        field = visible ? (
          <Textarea
            key={`var_${chiliVar.name}`}
            name={chiliVar.id}
            type="text"
            value={chiliVar.value}
            onChange={onControlValueChange}
            label={chiliVar.displayName}
            disabled={disabled || isVariableDisabled(chiliVar.name)}
            required={required}
            validator={required ? Validators.required : undefined}
            debug={debug}
          />
        ) : null;
        break;

      case 'formattedtext':
        field = visible ? (
          <Textarea
            key={`var_${chiliVar.name}`}
            name={chiliVar.id}
            type="formattedtext"
            value={formattedText(chiliVar.value)}
            onChange={this.handleFormattedTextChange}
            helperText={intl.formatMessage({
              id: 'VariablesForm.textarea.helperText',
              defaultMessage:
                'Use ^text^ for superscript and ~text~ for subscript.',
            })}
            label={chiliVar.displayName}
            disabled={disabled || isVariableDisabled(chiliVar.name)}
            required={required}
            validator={required ? Validators.required : undefined}
            debug={debug}
          />
        ) : null;
        break;

      case 'number':
        field = visible ? (
          <Input
            key={`var_${chiliVar.name}`}
            name={chiliVar.id}
            type="number"
            value={chiliVar.value}
            onChange={onControlValueChange}
            label={chiliVar.displayName}
            disabled={disabled || isVariableDisabled(chiliVar.name)}
            required={required}
            validator={required ? Validators.required : undefined}
            inputProps={{
              min: chiliVar.min,
              max: chiliVar.max,
              step: chiliVar.step,
            }}
            helperText={intl.formatMessage(
              {
                id: 'VariablesForm.number.helperText',
                defaultMessage: 'Mininum: 0, Maximum: 10, Step size: 1',
              },
              {
                min: chiliVar.min,
                max: chiliVar.max,
                step: chiliVar.step,
              },
            )}
            debug={debug}
          />
        ) : null;
        break;

      case 'list':
        field = visible ? (
          <Select
            key={`var_${chiliVar.name}_${index}`}
            name={chiliVar.id}
            label={chiliVar.displayName}
            value={chiliVar.value}
            onChange={onControlValueChange}
            options={chiliVar.options}
            disabled={disabled || isVariableDisabled(chiliVar.name)}
            required={required}
            validator={required ? Validators.required : undefined}
            debug={debug}
          />
        ) : null;
        break;

      case 'image': {
        if (!visible) return null;

        field = (
          <ChiliImage
            key={`var_icon_${chiliVar.name}_${index}`}
            label={chiliVar.displayName}
            value={chiliVar.value}
            onChange={assetId => this.handleAssetChange(chiliVar.id, assetId)}
            onClear={() => this.handleAssetClear(chiliVar.id)}
            folder={chiliVar.imagePulldownDirectory}
            disabled={disabled || isVariableDisabled(chiliVar.name)}
            required={required}
            validator={required ? Validators.required : undefined}
            hideGallery={hideGallery}
            debug={debug}
          />
        );
        break;
      }

      default:
        field = null;
        break;
    }

    return field;
  };

  render() {
    const { classes, loading, debug } = this.props;

    if (debug) {
      console.info('VariablesForm render');
    }

    return (
      <React.Fragment>
        {!loading && (
          <div className={classes.root}>
            <form className={classes.form}>{this.renderVariablesForm()}</form>
          </div>
        )}

        {loading && (
          <div className={classes.loader}>
            <Typography variant="h6" gutterBottom>
              <FormattedMessage
                id="VariablesForm.loader.title"
                defaultMessage="Just A Few Seconds"
              />
            </Typography>

            <Typography variant="h6" gutterBottom>
              <strong>
                <FormattedMessage
                  id="VariablesForm.loader.text"
                  defaultMessage="While We Fetch The Variables from the Document"
                />
              </strong>
            </Typography>

            <CircularProgress
              size={40}
              thickness={4}
              className={classes.loaderProgress}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const styles = {
  root: {},
  form: {},
  loader: {
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  loaderProgress: {
    marginTop: 20,
  },
};

// $FlowFixMe
export default connect()(withStyles(styles)(injectIntl(VariablesForm)));
