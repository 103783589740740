// @flow

import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import type { TypeSelectedFrame } from 'Components/Editor/Types';
import EditorApi from 'Components/Editor/EditorApi';
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import {
  FilterCenterFocus as FilterCenterFocusIcon,
  RotateRight as RotateRightIcon,
  SwapHoriz as SwapHorizIcon,
  SwapVert as SwapVertIcon,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from '@material-ui/icons';
import { STATUS } from 'Tools/Status';

type Props = {
  classes: Object,
  editorApi: EditorApi,
  selectedFrame: ?TypeSelectedFrame,
  imageRotation: number,
  imageWidth: number,
  imageHeight: number,
  imageFlip: string,
  intl: IntlShape,
  itemStatus: string,
};

const ZOOM_PERCENTAGE_STEP = 2;

class ImageToolbar extends React.PureComponent<Props> {
  setImageFlip = (value: string) => {
    const { editorApi, imageFlip } = this.props;

    let newImageFlip = value;
    if (imageFlip === newImageFlip) {
      newImageFlip = '';
    }

    editorApi.setImageFlip(newImageFlip);
  };

  handleRotationChange = (event: SyntheticInputEvent<*>) => {
    const { editorApi, imageRotation } = this.props;

    const value = parseFloat(event.target.value);
    if (value !== imageRotation && value >= -180 && value <= 180) {
      editorApi.setImageRotation(value);
    }
  };

  zoomIn = () => {
    const { editorApi, imageWidth, imageHeight } = this.props;

    const newWidth = imageWidth * (1 + ZOOM_PERCENTAGE_STEP / 100);
    const newHeight = imageHeight * (1 + ZOOM_PERCENTAGE_STEP / 100);

    editorApi.setImageFrameFitMode('manual');
    editorApi.setImageWidth(`${newWidth} mm`);
    editorApi.setImageHeight(`${newHeight} mm`);
  };

  zoomOut = () => {
    const { editorApi, imageWidth, imageHeight } = this.props;

    const newWidth = imageWidth / (1 + ZOOM_PERCENTAGE_STEP / 100);
    const newHeight = imageHeight / (1 + ZOOM_PERCENTAGE_STEP / 100);

    editorApi.setImageFrameFitMode('manual');
    editorApi.setImageWidth(`${newWidth} mm`);
    editorApi.setImageHeight(`${newHeight} mm`);
  };

  handleRotateClockwise = () => {
    const { editorApi, imageRotation: rotation } = this.props;

    let newRotation = -90;
    if (rotation < 180) {
      newRotation = rotation + 90;
    }
    editorApi.setImageRotation(newRotation);
  };

  fitImageProportionalOutside = () => {
    const { editorApi } = this.props;

    editorApi.setImageFrameFitPoint('center');
    editorApi.setImageFrameFitMode('proportional_outside');
    editorApi.setImageFrameFitMode('manual');
  };

  render() {
    const {
      editorApi,
      selectedFrame,
      classes,
      imageRotation,
      intl,
      itemStatus,
    } = this.props;

    if (
      !selectedFrame ||
      selectedFrame.type !== 'image' ||
      ![STATUS.DSP_REVIEW, STATUS.IN_REVIEW, STATUS.FEEDBACK_PROVIDED].includes(
        itemStatus,
      )
    ) {
      return null;
    }

    const frameConstraints = editorApi.getDocumentFrameConstraints();
    if (
      selectedFrame.lockContent === 'yes' ||
      (selectedFrame.lockContent === 'inherit' &&
        frameConstraints &&
        frameConstraints.lockContent !== 'no')
    ) {
      return null;
    }

    if (
      selectedFrame &&
      typeof selectedFrame.fitMode !== 'undefined' &&
      selectedFrame.fitMode !== 'manual'
    ) {
      editorApi.setImageFrameFitMode('manual');
    }

    return (
      <Grid container alignItems="center" className={classes.root}>
        <Tooltip
          title={intl.formatMessage({
            id: 'Editor.imageToolbar.proportionalFit',
            defaultMessage: 'Proportional fit',
          })}
          placement="top"
        >
          <IconButton
            onClick={() => this.fitImageProportionalOutside()}
            color="secondary"
          >
            <ZoomOutMapIcon />
          </IconButton>
        </Tooltip>

        <Divider orientation="vertical" />

        <Tooltip
          title={intl.formatMessage({
            id: 'Editor.imageToolbar.centeringImage',
            defaultMessage: 'Centering image',
          })}
          placement="top"
        >
          <IconButton onClick={() => editorApi.centerImage()} color="secondary">
            <FilterCenterFocusIcon />
          </IconButton>
        </Tooltip>

        <Divider orientation="vertical" />

        <Tooltip
          title={intl.formatMessage({
            id: 'Editor.imageToolbar.flipHorizontal',
            defaultMessage: 'Flip horizontal',
          })}
          placement="top"
        >
          <IconButton
            onClick={() => this.setImageFlip('horizontal')}
            color="secondary"
          >
            <SwapHorizIcon />
          </IconButton>
        </Tooltip>

        <Divider orientation="vertical" />

        <Tooltip
          title={intl.formatMessage({
            id: 'Editor.imageToolbar.flipVertical',
            defaultMessage: 'Flip vertical',
          })}
          placement="top"
        >
          <IconButton
            onClick={() => this.setImageFlip('vertical')}
            color="secondary"
          >
            <SwapVertIcon />
          </IconButton>
        </Tooltip>

        <Divider orientation="vertical" />

        <Tooltip
          title={intl.formatMessage({
            id: 'Editor.imageToolbar.rotateClockwise',
            defaultMessage: 'Rotate clockwise (90°)',
          })}
          placement="top"
        >
          <IconButton
            onClick={() => this.handleRotateClockwise()}
            color="secondary"
          >
            <RotateRightIcon />
          </IconButton>
        </Tooltip>

        <OutlinedInput
          id="img-rotation-degrees"
          value={imageRotation}
          onChange={this.handleRotationChange}
          type="number"
          className={classes.textField}
          startAdornment={
            <InputAdornment position="start">&deg;</InputAdornment>
          }
          inputProps={{
            min: -180,
            max: 180,
            className: classes.input,
          }}
          labelWidth={0}
        />

        <Divider orientation="vertical" />

        <Tooltip
          title={intl.formatMessage({
            id: 'Editor.imageToolbar.zoomIn',
            defaultMessage: 'Zoom in',
          })}
          placement="top"
        >
          <IconButton onClick={() => this.zoomIn()} color="secondary">
            <ZoomInIcon />
          </IconButton>
        </Tooltip>

        <Divider orientation="vertical" />

        <Tooltip
          title={intl.formatMessage({
            id: 'Editor.imageToolbar.zoomOut',
            defaultMessage: 'Zoom out',
          })}
          placement="top"
        >
          <IconButton onClick={() => this.zoomOut()} color="secondary">
            <ZoomOutIcon />
          </IconButton>
        </Tooltip>

        <Divider orientation="vertical" />
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    width: '100%',
    '& button': {
      padding: theme.spacing(0.5),
    },
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      height: 72,
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: {
    padding: '8.5px 14px',
  },
});

export default injectIntl(withStyles(styles)(ImageToolbar));
