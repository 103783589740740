// @flow
import type { TypeAction } from './Actions';
import type { TypeChiliAsset, TypeChiliIcon } from './Types';

export type TypeState = {
  admins: ?(TypeChiliAsset[]),
  uploads: ?(TypeChiliAsset[]),
  predefined: ?(TypeChiliAsset[]),
  icons: ?(TypeChiliIcon[]),
  adminsLoaded: boolean,
  uploadsLoaded: boolean,
  predefinedLoaded: boolean,
  cropDataLoaded: boolean,
};

const DEFAULT_STATE = {
  admins: null,
  uploads: null,
  predefined: null,
  icons: null,
  adminsLoaded: false,
  uploadsLoaded: false,
  predefinedLoaded: false,
  cropDataLoaded: false,
};

export default (
  state: TypeState = DEFAULT_STATE,
  action: TypeAction,
): TypeState => {
  switch (action.type) {
    case '@chili/get-uploaded-assets':
      return {
        ...state,
        uploads: action.payload,
        uploadsLoaded: true,
      };

    case '@chili/get-admin-assets':
      return {
        ...state,
        admins: action.payload,
        adminsLoaded: true,
      };

    case '@chili/get-assets':
      return {
        ...state,
        predefined: action.payload,
        predefinedLoaded: true,
      };

    case '@chili/get-icons':
      return {
        ...state,
        icons: action.payload,
      };

    case '@chili/crop-data-loaded-in-editor':
      return {
        ...state,
        cropDataLoaded: action.payload,
      };

    case '@chili/predefined-loaded':
      return {
        ...state,
        predefinedLoaded: action.payload,
      };

    case '@chili/uploads-loaded':
      return {
        ...state,
        uploadsLoaded: action.payload,
      };

    case '@chili/clear':
      return DEFAULT_STATE;

    default:
      return state;
  }
};
