// @flow

import type { TypeAction, TypeCompanyFolder } from './Actions';

type TypeState = {
  [string]: TypeCompanyFolder[],
};
const DEFAULT_STATE = {
  root: [],
};

export default (
  state: TypeState = DEFAULT_STATE,
  action: TypeAction,
): TypeState => {
  switch (action.type) {
    case '@folders/get-company-folders': {
      const folders = action.payload;
      let stateKey = 'root';

      if (action.data && action.data.folderId) {
        stateKey = action.data.folderId;
      }

      return {
        ...state,
        [stateKey]: [...folders],
      };
    }
    default:
      return state;
  }
};
