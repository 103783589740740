// @flow

import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import { ActionsSnack } from 'Components/Snack';
import Actions from './Actions';

const PreflightErrorButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <Button
      color="secondary"
      variant="contained"
      style={{ backgroundColor: '#f57f17' }}
      onClick={() => {
        dispatch(Actions.open());
        dispatch(ActionsSnack.reset());
      }}
    >
      {intl.formatMessage({
        id: 'Preflight.snack.button.preflight',
        defaultMessage: 'View',
      })}
    </Button>
  );
};

export default PreflightErrorButton;
