// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Snackbar,
  SnackbarContent,
  IconButton,
  withStyles,
} from '@material-ui/core';
import {
  CheckCircle as IconCheckCircle,
  Warning as IconWarning,
  Error as IconError,
  Info as IconInfo,
  Close as IconClose,
} from '@material-ui/icons';

import type { TypeSnack, TypeVariant } from './Actions';
import Actions from './Actions';

const variantIcon = {
  default: null,
  success: IconCheckCircle,
  warning: IconWarning,
  error: IconError,
  info: IconInfo,
  grey: IconInfo,
};

type PropsContent = {
  classes: Object,
  className: string,
  title: string,
  description: string,
  variant: TypeVariant,
  onClose: () => void,
  button: ?React.Node,
};

function MySnackbarContent(props: PropsContent) {
  const {
    classes,
    className,
    title,
    description,
    onClose,
    variant,
    button,
  } = props;
  const Icon = variantIcon[variant];
  const hasDescription = description.length > 0;

  return (
    <SnackbarContent
      classes={{
        root: `${classes[variant]} ${className} ${classes.root}`,
        action: classes.action,
      }}
      aria-describedby="client-snackbar"
      message={
        <div id="client-snackbar" className={classes.content}>
          {/* $FlowFixMe */}
          {Icon && (
            <Icon className={`${classes.icon} ${classes.iconVariant}`} />
          )}

          <div className={classes.message}>
            <Typography
              variant={hasDescription ? 'body1' : 'body2'}
              color="inherit"
              gutterBottom={hasDescription}
            >
              <strong>{title}</strong>
            </Typography>

            {hasDescription && (
              <Typography variant="body2" color="inherit">
                {description}
              </Typography>
            )}
          </div>
        </div>
      }
      action={[
        button,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={`${classes.icon} ${classes.iconClose}`}
          onClick={onClose}
        >
          <IconClose className={classes.icon} />
        </IconButton>,
      ]}
    />
  );
}

const stylesContent = theme => ({
  root: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    boxShadow: '0px 0 12px rgba(255, 255, 255, 0.3)',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  message: {},
  action: {},
  default: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  grey: {
    backgroundColor: theme.palette.grey.main,
    color: theme.palette.grey.contrastText,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(
      1,
    )}px 0`,
  },
  iconClose: {},
});

const MySnackbarContentWrapper = withStyles(stylesContent)(MySnackbarContent);

type Props = {
  classes: Object,
  snack: TypeSnack,
  dispatch: (action: any) => void,
};

class Snack extends React.Component<Props> {
  handleClose = (event, reason) => {
    const { dispatch } = this.props;

    if (reason === 'clickaway') {
      return;
    }

    dispatch(Actions.reset());
  };

  render() {
    const { classes, snack } = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snack.open}
        autoHideDuration={10000}
        onClose={this.handleClose}
        classes={{ root: classes.root, anchorOriginTopCenter: classes.top }}
      >
        <MySnackbarContentWrapper
          onClose={this.handleClose}
          variant={snack.variant}
          title={snack.data.title}
          description={snack.data.description}
          button={snack.data.button}
        />
      </Snackbar>
    );
  }
}

const styles = () => ({
  root: {},
  top: {},
});

const mapStateToProps: (state: any) => any = state => ({
  snack: state.snack,
});

// $FlowFixMe
export default connect(mapStateToProps)(withStyles(styles)(Snack));
