// @flow

type TypeCommentPayload = {
  content: string,
  frame?: ?string,
};

export type TypeMeta = {
  limit: number,
  page: number,
  total: number,
  totalPages: number,
};

type TypeAuthor = {
  id: number,
  email: string,
  firstName: string,
  lastName: string,
  createdAt: string,
  updatedAt: string,
};

export type TypeComment = {
  id: number,
  content: string,
  author: TypeAuthor,
  frame?: string,
  createdAt: string,
  updatedAt: string,
};

type TypeFormError = {
  key: string,
  message: string,
};

export type TypeApiError = {
  code: number,
  message: string,
  errors: TypeFormError[],
};

type TypeResponseError = (responseData: TypeApiError) => void;

type TypeResponseSuccess = (responseData: {
  result: TypeComment[],
  meta: TypeMeta,
}) => void;

export type TypeActionAdd = {
  type: '@comment/add',
  payload: TypeComment,
};

type TypeActionGet = {
  type: '@comment/all',
  payload: {
    result: TypeComment[],
    meta: TypeMeta,
  },
};

type TypeActionFilter = {
  type: '@comment/filter',
  payload: string,
};

export type TypeAction = TypeActionAdd | TypeActionGet | TypeActionFilter;

export const Actions = {
  add: (
    serieId: number,
    serieItemId: number,
    data: TypeCommentPayload,
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@comment/add',
    meta: {
      type: 'api',
      method: 'POST',
      skipSnack: true,
      url: `/serie/${serieId}/item/${serieItemId}/comment`,
      body: JSON.stringify(data),
      onSuccess,
      onError,
    },
  }),

  get: (
    serieId: number,
    serieItemId: number,
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@comment/all',
    meta: {
      type: 'api',
      method: 'GET',
      skipSnack: true,
      url: `/serie/${serieId}/item/${serieItemId}/comment`,
      onSuccess,
      onError,
    },
  }),

  filter: (filter?: ?string) => ({
    type: '@comment/filter',
    payload: filter || null,
  }),
};

export default Actions;
