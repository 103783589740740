// @flow

import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// $FlowFixMe
import { useSelector, useDispatch } from 'react-redux';
// $FlowFixMe
import moment from 'moment';

import {
  Paper,
  Button,
  Typography,
  IconButton,
  InputBase,
  withStyles,
} from '@material-ui/core';
import {
  Send as IconSend,
  Close as IconClose,
  ChevronLeft as IconPrev,
  ChatBubbleOutline as IconChatBubbleOutline,
  // $FlowFixMe
} from '@material-ui/icons';

import { STATUS } from 'Tools/Status';
import type { TypeStateMe } from 'Components/AA';
import { ActionsSnack } from 'Components/Snack';
import type { TypeStore } from 'Types';
import { extractMessageFromErrors } from 'Components/FormHandler';
import type { TypeSelectedFrame } from 'Components/Editor/Types';
import { ActionsSeries } from 'Routes/Series';
import { Actions } from './Actions';
import type { TypeComment, TypeApiError } from './Actions';

type Props = {
  open: boolean,
  seriesId: number,
  itemId: number,
  itemStatus: string,
  selectedFrame: ?TypeSelectedFrame,
  classes: Object,
  onToggleComments: () => void,
  onUnselect: ?() => void,
};

const Comments = (props: Props) => {
  const {
    open,
    seriesId,
    itemId,
    itemStatus,
    selectedFrame,
    onToggleComments,
    onUnselect,
    classes,
  } = props;
  const dispatch = useDispatch();
  const intl = useIntl();

  const allowedStatuses = [
    STATUS.IN_REVIEW,
    STATUS.FEEDBACK_PROVIDED,
    STATUS.APPROVED,
  ];

  const commentingEnabled = allowedStatuses.includes(itemStatus);

  const [comment, setComment] = React.useState('');

  const me: TypeStateMe = useSelector((state: TypeStore) => state.me);

  const comments: TypeComment[] = useSelector(
    (state: { [string]: any }) => state.comments.filtered,
  );

  let inputRef;

  React.useEffect(() => {
    dispatch(Actions.get(seriesId, itemId));
  }, [seriesId, itemId, dispatch]);

  React.useEffect(() => {
    dispatch(Actions.filter(selectedFrame ? selectedFrame.id : null));
  }, [selectedFrame, dispatch]);

  React.useEffect(() => {
    if (open) {
      inputRef.focus();
    }
  }, [open, inputRef]);

  const addComment = () => {
    if (!commentingEnabled) {
      dispatch(
        ActionsSnack.show(
          intl.formatMessage({
            id: 'Comments.snack.notAllowed.title',
            defaultMessage: 'Commenting not allowed.',
          }),
          intl.formatMessage(
            {
              id: 'Comments.snack.notAllowed.description',
              defaultMessage:
                'Comments can only be added if the item status is one of {allowedStatuses}.',
            },
            {
              allowedStatuses: allowedStatuses.join(', '),
            },
          ),
          'warning',
        ),
      );

      return;
    }

    if (comment !== '') {
      dispatch(
        Actions.add(
          seriesId,
          itemId,
          {
            content: comment,
            frame: selectedFrame ? selectedFrame.id : null,
          },
          () => {
            dispatch(
              Actions.get(seriesId, itemId, () => {
                dispatch(
                  Actions.filter(selectedFrame ? selectedFrame.id : null),
                );
              }),
            );
            dispatch(ActionsSeries.getItem(seriesId, itemId));
            setComment('');
          },
          (responseData: TypeApiError) => {
            const errorMessage = extractMessageFromErrors(
              'itemId',
              responseData.errors,
            );
            dispatch(
              ActionsSnack.show(
                intl.formatMessage({
                  id: 'Comments.snack.error.title',
                  defaultMessage:
                    'There was a problem with saving your comment.',
                }),
                errorMessage ||
                  intl.formatMessage({
                    id: 'Comments.snack.error.description',
                    defaultMessage: 'Please try again later.',
                  }),
                'error',
              ),
            );
          },
        ),
      );
    }
  };

  return (
    <aside
      className={`${classes.aside} ${open ? classes.open : classes.closed}`}
    >
      <Paper
        classes={{
          root: classes.paper,
        }}
        square
      >
        <div className={classes.content}>
          <div className={classes.commentsHeader}>
            {selectedFrame && onUnselect && (
              <IconButton onClick={onUnselect} size="small">
                <IconPrev />
              </IconButton>
            )}
            <Typography variant="h5" className={classes.commentsTitle}>
              {selectedFrame ? (
                <span className={classes.frameLabel}>
                  {selectedFrame.label}
                </span>
              ) : (
                <FormattedMessage
                  id="Comments.documentComments"
                  defaultMessage="Document comments"
                />
              )}
            </Typography>
          </div>
          <div className={classes.comments}>
            {comments.map(item => (
              <div
                key={item.id}
                className={
                  me && me.id === item.author.id
                    ? classes.myComment
                    : classes.comment
                }
              >
                <div className={classes.commentDetails}>
                  <div className={classes.author}>
                    {item.author.firstName} {item.author.lastName}
                  </div>
                  <div className={classes.date}>
                    {moment(item.createdAt).format('DD/MM/YYYY HH:mm')}
                  </div>
                </div>
                <span>{item.content}</span>
              </div>
            ))}
          </div>
          <div className={classes.form}>
            <InputBase
              rows={3}
              multiline
              fullWidth
              classes={{ root: classes.textarea }}
              value={comment}
              onChange={event => setComment(event.target.value)}
              inputRef={ref => {
                inputRef = ref;
              }}
              disabled={!commentingEnabled}
              placeholder={
                !commentingEnabled
                  ? `Comments can only be added if the item status is one of ${allowedStatuses.join(
                      ', ',
                    )}.`
                  : null
              }
            />
            <div className={classes.formButtons}>
              <Button
                color="primary"
                variant="contained"
                classes={{ root: classes.formButton }}
                onClick={addComment}
              >
                <IconSend fontSize="small" className={classes.formButtonIcon} />
                <FormattedMessage
                  id="Comments.button.send"
                  defaultMessage="SEND"
                />
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.verticalBar}>
          <IconButton onClick={onToggleComments}>
            {open ? <IconClose /> : <IconChatBubbleOutline />}
          </IconButton>
          <div className={classes.verticalTextPlaceholder}>
            <div className={classes.verticalText}>
              <FormattedMessage
                id="Comments.verticalText"
                defaultMessage={`{commentsCount, number} {commentsCount, plural,
                  one {COMMENT}
                  other {COMMENTS}
                }`}
                values={{ commentsCount: comments.length }}
              />
            </div>
          </div>
        </div>
      </Paper>
    </aside>
  );
};

const styles = theme => ({
  aside: {
    position: 'fixed',
    top: 128,
    bottom: 64,
    width: '35%',
    zIndex: 999,
    transition: 'left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  open: {
    left: 0,
    zIndex: 1000,
  },
  closed: {
    left: 'calc(-35% + 58px)',
    height: 220,
  },
  paper: {
    height: '100%',
    display: 'flex',
    color: theme.palette.text.secondary,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  comments: {
    padding: 12,
    maxHeight: 'calc(100% - 140px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  commentsHeader: {
    display: 'flex',
    paddingLeft: 33, // to align with the button above - 24 + (48 - 30)/2.
    // 24px - padding of header above, 48px - width of big button, 30px - width of small button.
    paddingRight: 63, // to center the title - 33 + 30 / 33 - padding left, 30 - width of small button.
    backgroundColor: '#f7f7f7',
    alignItems: 'center',
  },
  commentsTitle: {
    flexGrow: 1,
    height: 48,
    lineHeight: '48px',
    fontSize: '1rem',
    textAlign: 'center',
    overflow: 'hidden',
  },
  frameLabel: {
    textTransform: 'lowercase',
  },
  comment: {
    backgroundColor: '#dee3e7',
    margin: `0 42px 12px 0`,
    padding: 12,
    borderBottomRightRadius: 12,
  },
  myComment: {
    backgroundColor: '#fcd9ec',
    margin: `0 0 12px 42px`,
    padding: 12,
    borderBottomLeftRadius: 12,
  },
  commentDetails: {
    display: 'flex',
    marginBottom: 16,
  },
  author: {
    fontWeight: 'bold',
  },
  date: {
    marginLeft: 'auto',
  },
  form: {
    padding: 12,
    marginTop: 'auto',
    backgroundColor: '#f7f7f7',
    height: 140,
  },
  textarea: {
    marginBottom: 6,
    backgroundColor: '#ffffff',
    border: '1px solid #b4b4b4',
    padding: theme.spacing(1),
  },
  formButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formButton: {
    borderRadius: 0,
    boxShadow: 'none',
    textTransform: 'uppercase',
    letterSpacing: '1px',
  },
  formButtonIcon: {
    marginRight: 6,
  },
  verticalBar: {
    width: 58,
    backgroundColor: '#b4b4b4',
    textAlign: 'center',
    paddingTop: 6,
  },
  verticalTextPlaceholder: {
    position: 'relative',
    height: 140,
    marginTop: 6,
  },
  verticalText: {
    position: 'absolute',
    top: 140,
    width: 140,
    lineHeight: '58px',
    transform: 'rotate(-90deg)',
    transformOrigin: 'top left',
    textAlign: 'right',
  },
});

export default withStyles(styles)(Comments);
