// @flow

import type { TypeFormError } from './Types';

type TypeActionAdd = {
  type: '@error/add',
  payload: TypeFormError[],
};

type TypeActionClear = {
  type: '@error/clear',
};

export type TypeAction = TypeActionAdd | TypeActionClear;

const Actions = {
  add: (data: TypeFormError[]) =>
    ({
      type: '@error/add',
      payload: data,
    }: TypeActionAdd),

  clear: () =>
    ({
      type: '@error/clear',
    }: TypeActionClear),
};

export default Actions;
