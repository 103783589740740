// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core';
// $FlowFixMe
import 'whatwg-fetch';

/**
 * References:
 * - Drag'n'Drop Event Handling Gotchas I Wish I Knew Earlier - https://timonweb.com/tutorials/dragndrop-gotchas-in-htmljs/
 */

type TypeEntity = {
  id: number,
  type: string,
  property?: string,
};

type Props = {
  classes: Object,
  uploadUrl: string,
  entity?: TypeEntity,
  fieldName: string,
  onUploadComplete?: (files: any[]) => void,
  onError?: ([{ [string]: string }]) => void,
  className?: string,
  children?: React.Node,
  multiple?: boolean,
};

type State = {
  status: 'DEFAULT' | 'ON_DROP_AREA' | 'UPLOADING' | 'SUCCESS' | 'ERROR',
};

class DragAndDropUploader extends React.PureComponent<Props, State> {
  static defaultProps = {
    onUploadComplete: null,
    className: '',
    children: null,
    multiple: false,
    onError: null,
    entity: null,
  };

  state = {
    status: 'DEFAULT',
  };

  refElement: ?HTMLDivElement;

  componentDidMount() {
    window.addEventListener('dragover', this.handleGlobalDragover);
    window.addEventListener('drop', this.handleGlobalDrop);
  }

  componentWillUnmount() {
    window.removeEventListener('dragover', this.handleGlobalDragover);
    window.removeEventListener('drop', this.handleGlobalDrop);
  }

  upload = (files: Object | Array<Object>) => {
    const { uploadUrl, onUploadComplete, entity, onError } = this.props;

    this.setState({
      status: 'UPLOADING',
    });

    const formData = new FormData();

    // $FlowFixMe
    Object.keys(files).map(key => {
      const file = files[key];

      formData.append(`image${key}`, file);
      if (entity) {
        formData.append('entity', entity.type);
        formData.append('id', entity.id.toString());

        if (entity.property) {
          formData.append('property', entity.property);
        }
      }

      return null;
    });
    const numFiles = Array.isArray(files)
      ? files.length
      : Object.keys(files).length;

    formData.append('numFiles', numFiles.toString());

    if (uploadUrl) {
      fetch(uploadUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(response => {
          if (response.status !== 200 && response.status !== 201) {
            response.json().then(responseJson => {
              if (onError) {
                onError(responseJson.errors);
                throw responseJson.message;
              }
            });
          }

          return response.json();
        })
        .then(filesData => {
          this.setState({
            status: 'SUCCESS',
          });

          setTimeout(
            () => {
              this.setState({
                status: 'DEFAULT',
              });
            },
            2000,
            false,
          );

          if (onUploadComplete) {
            onUploadComplete(filesData);
          }
        })
        .catch(error => {
          this.setState({
            status: 'ERROR',
          });

          console.error(error);

          setTimeout(
            () => {
              this.setState({
                status: 'DEFAULT',
              });
            },
            2000,
            false,
          );
        });
    }
  };

  isTargetInTheDropArea = (event: SyntheticDragEvent<*>) => {
    if (!this.refElement) {
      console.error('Drag and drop element is missing!');
      return false;
    }

    // SyntheticDragEvent has wrong definition for event.target
    // $FlowFixMe
    return this.refElement.contains(event.target);
  };

  handleGlobalDragover = (event: SyntheticDragEvent<*>) => {
    event.preventDefault();

    this.setState({
      status: this.isTargetInTheDropArea(event) ? 'ON_DROP_AREA' : 'DEFAULT',
    });
  };

  handleGlobalDrop = (event: SyntheticDragEvent<*>) => {
    event.preventDefault();

    this.setState({
      status: 'DEFAULT',
    });

    const { uploadUrl } = this.props;

    if (this.isTargetInTheDropArea(event) && uploadUrl) {
      const files = {
        ...event.dataTransfer.files,
      };

      this.upload(files);
    }
  };

  handleFieldChange = (event: SyntheticInputEvent<*>) => {
    this.upload(event.target.files);
  };

  getStatusClass = () => {
    const { status } = this.state;

    switch (status) {
      case 'ON_DROP_AREA': {
        return 'isDragover';
      }

      case 'UPLOADING': {
        return 'isUploading';
      }

      case 'SUCCESS': {
        return 'isSuccess';
      }

      case 'ERROR': {
        return 'isError';
      }

      default:
        return '';
    }
  };

  render() {
    const { classes, className, fieldName, children, multiple } = this.props;

    return (
      <div
        // eslint-disable-next-line no-return-assign
        ref={node => (this.refElement = node)}
      >
        <div
          className={`${classes.box} ${
            classes.hasAdvancedUpload
          } ${this.getStatusClass()}`}
        >
          <div className={`${classes.boxInput} ${className || ''}`}>
            <input
              className={classes.boxFile}
              id="file"
              type="file"
              name={fieldName}
              data-multiple-caption="{count} files selected"
              multiple={multiple}
              onChange={this.handleFieldChange}
            />

            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
            <label htmlFor="file">
              <svg
                className={classes.boxIcon}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 43"
              >
                <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z" />
              </svg>
              <strong>
                <FormattedMessage
                  id="DragAndDropUploader.input.file.choose"
                  defaultMessage="Choose a file"
                />
              </strong>
              <span className={classes.boxDragndrop}>
                <FormattedMessage
                  id="DragAndDropUploader.input.file.drag"
                  defaultMessage=" or drag it here"
                />
              </span>
              .
            </label>
          </div>

          {children}

          <div className={classes.boxDragover}>
            <svg
              className={classes.boxIcon}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 43"
            >
              <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z" />
            </svg>

            <br />

            <span>
              <FormattedMessage
                id="DragAndDropUploader.input.file.drop"
                defaultMessage="Drop it here"
              />
            </span>
          </div>

          <div className={classes.boxUploading}>
            <FormattedMessage
              id="DragAndDropUploader.input.file.uploading"
              defaultMessage="Uploading"
            />
            &hellip;
          </div>

          <div className={classes.boxSuccess}>
            <FormattedMessage
              id="DragAndDropUploader.input.file.done"
              defaultMessage="Done!"
            />
          </div>

          <div className={classes.boxError}>
            <FormattedMessage
              id="DragAndDropUploader.message.error"
              defaultMessage="Error!"
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  box: {
    position: 'relative',
    margin: '10px 0',
    backgroundColor: theme.palette.neutral.light,

    '&:hover': {
      cursor: 'pointer',
    },
    '&.isDragover, &.isUploading, &.isSuccess, &.isError': {
      'pointer-events': 'none',
      '-webkit-user-drag': 'none',
      '-khtml-user-drag': 'none',
      '-moz-user-drag': 'none',
      '-o-user-drag': 'none',
      'user-drag': 'none',

      outline: '2px dashed #92b0b3',
      outlineOffset: '-10px',
      '-webkit-transition':
        'outline-offset .15s ease-in-out, background-color .15s linear',
      transition:
        'outline-offset .15s ease-in-out, background-color .15s linear',
    },
    '&.isDragover $boxInput, &.isUploading $boxInput, &.isSuccess $boxInput, &.isError $boxInput': {
      visibility: 'hidden',
    },
    '&.isUploading $boxUploading, &.isDragover $boxDragover, &.isSuccess $boxSuccess, &.isError $boxError': {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      fontSize: 20,
      lineHeight: '20px',
      backgroundColor: '#c8dadf',
      opacity: 0.7,
    },
    '&.hasAdvancedUpload $boxDragndrop': {
      display: 'inline',
    },
    '&.isUploading $boxUploading': {
      color: '#5757b3',
    },
    '&.isDragover $boxDragover': {
      color: '#000',
    },
    '&.isSuccess boxSuccess': {
      color: '#56b346',
    },
    '&.isError $boxError': {
      color: '#b35855',
    },
  },
  boxDragndrop: {
    display: 'inline',
  },
  boxInput: {
    display: 'inline-block',

    '& $boxIcon': {
      width: 31,
      height: 24,
      marginRight: 10,
      fill: theme.palette.primary.main,
    },
  },
  boxDragover: {
    position: 'relative',
    display: 'none',

    '& $boxIcon': {
      width: 31,
      height: 24,
      marginRight: 10,
      fill: theme.palette.primary.main,
      display: 'block',
    },
  },
  isDragover: {},
  isUploading: {},
  isSuccess: {},
  isError: {},
  hasAdvancedUpload: {},
  boxIcon: {},
  boxUploading: {
    display: 'none',
    fontStyle: 'italic',
  },
  boxSuccess: {
    display: 'none',
  },
  boxError: {
    display: 'none',
  },
  boxFile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,

    '& + label': {
      padding: 20,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      display: 'inline-block',
      overflow: 'hidden',
    },
    '& + label:hover strong, &:focus + label strong, &.hasFocus + label strong': {
      color: theme.palette.primary.main,
    },
    '&:focus + label, &.hasFocus + label': {
      outline: '1px dotted #000',
    },
  },
  hasFocus: {},
});

export default withStyles(styles)(DragAndDropUploader);
