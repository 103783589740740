// @flow

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Dialog, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AssetsList } from 'Components/Editor/control';
import type { TypeChiliAsset } from 'Components/Editor/Types';
import { Roles } from 'Components/AA';

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  actions: {
    padding: `${theme.spacing(3)}px 0`,
    textAlign: 'center',

    '& button': {
      margin: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  FormControlLabel: {
    width: '50px',
  },
}));

type DialogProps = {
  onClose: (selectedAsset: ?TypeChiliAsset) => void,
  open: boolean,
  allowUpload?: boolean,
  folder: ?string,
};

export default function ChiliAssetDialog(props: DialogProps) {
  const [mode, setMode] = React.useState('light');
  const classes = useStyles();
  const me = useSelector(state => state.me);
  const isAdmin =
    me &&
    me.roles.some(role => [Roles.ADMIN, Roles.SUPER_ADMIN].includes(role));
  const { onClose, open, folder, allowUpload } = props;

  const handleChange = () => {
    if (mode === 'light') {
      setMode('dark');
    } else {
      setMode('light');
    }
  };

  let variant = 'assets';
  if (folder) {
    variant = 'icons';
  } else if (allowUpload && isAdmin) {
    variant = 'uploads-from-path';
  } else if (allowUpload) {
    variant = 'uploads';
  }

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle id="mapping-dialog-title">
        {allowUpload ? (
          <FormattedMessage
            id="ChiliAssetDialog.dailog.selectUpload.title"
            defaultMessage="Select or upload an asset"
          />
        ) : (
          <div className={classes.header}>
            <FormattedMessage
              id="ChiliAssetDialog.dailog.select.title"
              defaultMessage="Select an asset"
            />
            <FormControlLabel
              classes={{ label: classes.FormControlLabel }}
              control={<Switch checked={mode === 'dark'} />}
              label={
                <FormattedMessage
                  id={
                    mode === 'light'
                      ? 'ChiliAssetDialog.dialog.lightMode'
                      : 'ChiliAssetDialog.dialog.darkMode'
                  }
                  defaultMessage={mode === 'light' ? 'Light' : 'Dark'}
                />
              }
              onChange={handleChange}
            />
          </div>
        )}
      </DialogTitle>
      <div
        className={classes.root}
        style={{ backgroundColor: mode === 'light' ? 'white' : '#737273' }}
      >
        <AssetsList
          variant={variant}
          onChange={onClose}
          folder={folder}
          mode={mode}
        />
      </div>
    </Dialog>
  );
}

ChiliAssetDialog.defaultProps = {
  allowUpload: false,
};
