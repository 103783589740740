// @flow
import React, { useState } from 'react';
import {
  ExpandLess,
  ExpandMore,
  Cancel as CancelIcon,
} from '@material-ui/icons';
import {
  withStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
} from '@material-ui/core';
import CollapseMenuListItem from './CollapseMenuListItem';

type Props = {
  classes: Object,
  preflightMessageKey: string,
  preflightMessageErrorData: Array<Object>,
};

const CollapseMenu = (props: Props) => {
  const { classes, preflightMessageKey, preflightMessageErrorData } = props;
  const [isOpen, setIsOpen] = useState(true);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleOpen}
        classes={{ root: classes.listItem }}
      >
        <ListItemIcon
          classes={{
            root: classes.listItemIcon,
          }}
        >
          <CancelIcon classes={{ root: classes.cancelIcon }} />
        </ListItemIcon>
        <ListItemText primary={preflightMessageKey} />
        {isOpen ? (
          <ExpandLess classes={{ root: classes.expandIcon }} />
        ) : (
          <ExpandMore classes={{ root: classes.expandIcon }} />
        )}
      </ListItem>
      <Divider />
      <Collapse
        classes={{ wrapperInner: classes.collapseMenuInnerWrapper }}
        in={isOpen}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {preflightMessageErrorData.map((data, indx) => {
            return (
              <CollapseMenuListItem
                key={`id_CollapseMenuListItem_${indx.toString()}`}
                preflightMessageErrorDataItem={data}
              />
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

const styles = () => ({
  listItem: {
    padding: '0',
    margin: '10px 0',
  },
  listItemIcon: {
    minWidth: '0',
    margin: '0 5px 0 12px',
  },
  cancelIcon: {
    color: 'red',
  },
  expandIcon: {
    margin: '0 15px 0 0',
  },
  collapseMenuInnerWrapper: {
    backgroundColor: '#f7f7f7',
  },
});

export default withStyles(styles)(CollapseMenu);
