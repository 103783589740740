// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import {
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  withStyles,
} from '@material-ui/core';
import type {
  TypeInputEventHandler,
  TypeFieldMargin,
  TypeSelectOption,
} from './Types';

type Props = {
  classes: Object,
  id: string,
  name: string,
  options: TypeSelectOption[],
  label?: string,
  value?: string,
  margin?: TypeFieldMargin,
  error?: boolean,
  required?: boolean,
  helperText?: string,
  disabled?: boolean,
  onChange?: TypeInputEventHandler,
};

type State = {
  labelWidth: number,
};

class BaseSelect extends React.Component<Props, State> {
  static defaultProps = {
    value: '',
    label: undefined,
    margin: 'normal',
    error: undefined,
    required: false,
    helperText: undefined,
    disabled: false,
    onChange: undefined,
  };

  state = {
    labelWidth: 0,
  };

  refLabel = null;

  componentDidMount() {
    // $FlowFixMe
    const width = this.refLabel.offsetWidth;

    if (this.refLabel && width) {
      this.setState({
        labelWidth: width,
      });
    }
  }

  handleChange = (event: SyntheticInputEvent<*>) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const {
      classes,
      id,
      name,
      value,
      options,
      label,
      margin,
      error,
      required,
      helperText,
      disabled,
    } = this.props;
    const { labelWidth } = this.state;

    return (
      <React.Fragment>
        <FormControl
          className={classes.formControl}
          margin={margin}
          error={error}
          required={required}
          disabled={disabled}
          classes={{
            root: classes.formControl,
          }}
          fullWidth
        >
          <InputLabel
            htmlFor={id}
            ref={ref => {
              // eslint-disable-next-line react/no-find-dom-node
              this.refLabel = ReactDOM.findDOMNode(ref);
            }}
          >
            {label}
          </InputLabel>

          <Select
            value={value}
            input={
              <OutlinedInput name={name} id={id} labelWidth={labelWidth} />
            }
            onChange={this.handleChange}
          >
            {options.map((item: TypeSelectOption) => (
              <MenuItem value={item.value} key={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>

          {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      </React.Fragment>
    );
  }
}

const styles = () => ({
  formControl: {},
});

export default withStyles(styles)(BaseSelect);
