// @flow

import type { TypeAction, TypeSnack } from './Actions';

export type TypeState = TypeSnack;

export const DEFAULT_STATE: TypeSnack = {
  open: false,
  variant: 'default',
  data: {
    title: '',
    description: '',
    button: undefined,
  },
};

export default (state: TypeState = DEFAULT_STATE, action: TypeAction) => {
  switch (action.type) {
    case '@snack/show':
      return { ...action.payload };

    case '@snack/reset':
      return DEFAULT_STATE;

    default:
      return state;
  }
};
