// @flow

import * as React from 'react';
import { withStyles } from '@material-ui/core';

type Props = {
  classes: Object,
  isActive?: boolean,
};

class RenderCounter extends React.Component<Props> {
  static defaultProps = {
    isActive: false,
  };

  renders = 0;

  render() {
    const { classes, isActive } = this.props;

    this.renders += 1;

    return isActive ? (
      <span className={classes.root}>{this.renders}</span>
    ) : null;
  }
}

const styles = {
  root: {
    display: 'block',
    width: 30,
    height: 30,
    borderRadius: '0 0 50% 50%',
    padding: 4,
    margin: 4,
    backgroundColor: 'lime',
    fontSize: 14,
    lineHeight: '24px',
    color: '#000',
    fontWeight: 'bold',
    textAlign: 'center',
  },
};

export default withStyles(styles)(RenderCounter);
