// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@material-ui/icons';
import {
  withStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { truncateText } from 'Utils/Utils';
import Actions from './Actions';

type Props = {
  classes: Object,
  preflightMessageErrorDataItem: Object,
};

const CollapseMenuListItem = (props: Props) => {
  const dispatch = useDispatch();
  const { classes, preflightMessageErrorDataItem } = props;
  const [isChecked, setIsChecked] = useState(false);

  const handleCheck = () => {
    setIsChecked(!isChecked);
    dispatch(Actions.checkError(!isChecked ? 1 : -1));
  };

  if (!preflightMessageErrorDataItem) {
    return null;
  }

  return (
    <ListItem classes={{ root: classes.listItem }}>
      <ListItemText
        classes={{ root: classes.listItemTextNested }}
        primary={truncateText(
          (preflightMessageErrorDataItem.variable &&
            preflightMessageErrorDataItem.variable.displayName) ||
            preflightMessageErrorDataItem.text ||
            preflightMessageErrorDataItem.descriptionValue ||
            preflightMessageErrorDataItem.frameName,
          120,
        )}
      />
      <ListItemIcon onClick={handleCheck} classes={{ root: classes.checkBox }}>
        {isChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
      </ListItemIcon>
    </ListItem>
  );
};

const styles = () => ({
  listItem: {
    padding: '0',
    margin: '10px 0',
  },
  listItemIcon: {
    minWidth: '0',
    margin: '0 5px 0 12px',
  },
  checkBox: {
    minWidth: 0,
    margin: '0 15px 0 0',
  },
  listItemTextNested: {
    margin: '10px 20px',
    wordWrap: 'break-word',
  },
});

export default withStyles(styles)(CollapseMenuListItem);
