// @flow

import { createTheme } from '@material-ui/core/styles';

const themeSettings = {
  palette: {
    primary: {
      main: '#e20079',
    },
    secondary: {
      main: '#333',
    },
    neutral: {
      light: '#dedede',
      main: '#aaa',
      dark: '#747474',
    },
    success: {
      light: '#dae9d0',
      main: '#5daa52',
      dark: '#5daa52',
      contrastText: '#fff',
    },
    error: {
      main: '#e52238',
      contrastText: '#fff',
    },
    warning: {
      light: '#fde1c8',
      main: '#df7927',
      dark: '#df7927',
      contrastText: '#fff',
    },
    info: {
      light: '#4791db',
      main: '#1976d2',
      dark: '#115293',
      contrastText: '#fff',
    },
    grey: {
      main: '#888',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
    body1: {
      letterSpacing: '1px',
    },
    body2: {
      letterSpacing: '1px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
};

export const themeLight = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: themeSettings.palette.primary.main,
    },
    secondary: {
      main: themeSettings.palette.secondary.main,
    },
    neutral: {
      ...themeSettings.palette.neutral,
    },
    success: {
      ...themeSettings.palette.success,
    },
    error: {
      main: themeSettings.palette.error.main,
    },
    warning: {
      ...themeSettings.palette.warning,
    },
    info: {
      ...themeSettings.palette.info,
    },
    grey: {
      ...themeSettings.palette.grey,
    },
  },
  typography: {
    ...themeSettings.typography,
  },
  overrides: {
    ...themeSettings.overrides,
  },
});

export const themeDark = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: themeSettings.palette.primary.main,
    },
    secondary: {
      main: themeSettings.palette.secondary.main,
    },
    neutral: {
      ...themeSettings.palette.neutral,
    },
    success: {
      ...themeSettings.palette.success,
    },
    error: {
      main: themeSettings.palette.error.main,
    },
    warning: {
      ...themeSettings.palette.warning,
    },
    info: {
      ...themeSettings.palette.info,
    },
    grey: {
      ...themeSettings.palette.grey,
    },
  },
  typography: {
    ...themeSettings.typography,
  },
  overrides: {
    ...themeSettings.overrides,
  },
});
