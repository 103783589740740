// @flow

import type { TypeAction, TypeSettings as TypeState } from './Actions';

const DEFAULT_STATE = [];

export default (
  state: TypeState = DEFAULT_STATE,
  action: TypeAction,
): TypeState => {
  switch (action.type) {
    case '@settings/get':
      return [...state, ...action.payload];

    default:
      return state;
  }
};
