// @flow

import { combineReducers } from 'redux';

import { ReducerSnack } from 'Components/Snack';
import { ReducerFormErrors } from 'Components/FormHandler';
import { ReducerMe } from 'Components/AA';
import { ReducerSeries } from 'Routes/Series';
import { ReducerAssets } from 'Components/Editor';
import { ReducerComments } from 'Components/Comments';
import { ReducerSettings } from 'Routes/Settings';
import { ReducerFolders } from 'Components/Folders';
import { ReducerPreflightErrors } from 'Components/PreflightErrors';

const reducers: any = combineReducers({
  snack: ReducerSnack,
  formErrors: ReducerFormErrors,
  me: ReducerMe,
  series: ReducerSeries,
  assets: ReducerAssets,
  comments: ReducerComments,
  settings: ReducerSettings,
  companyFolders: ReducerFolders,
  preflightErrors: ReducerPreflightErrors,
});

export default reducers;
