// @flow

export type TypeStatus =
  | 'created'
  | 'importing_items'
  | 'in_review'
  | 'dsp_review'
  | 'errored'
  | 'in_production'
  | 'completed'
  | 'incomplete'
  | 'archived'
  | 'feedback_provided'
  | 'approved';

export const STATUS = {
  CREATED: 'created',
  IMPORTING_ITEMS: 'importing_items',
  IN_REVIEW: 'in_review',
  DSP_REVIEW: 'dsp_review',
  ERRORED: 'errored',
  IN_PRODUCTION: 'in_production',
  INCOMPLETE: 'incomplete',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
  FEEDBACK_PROVIDED: 'feedback_provided',
  APPROVED: 'approved',
};

export const SERIES_ALREADY_APPROVED_STATUSES = [
  STATUS.APPROVED,
  STATUS.IN_PRODUCTION,
  STATUS.INCOMPLETE,
  STATUS.COMPLETED,
];

export const getColorsByStatus = (status: ?TypeStatus) => {
  switch (status) {
    case STATUS.DSP_REVIEW:
      return {
        light: 'rgb(255, 220, 239)',
        dark: 'rgb(226, 0, 122)',
      };

    case STATUS.IN_REVIEW:
      return {
        light: '#fde1c8',
        dark: '#df7927',
      };

    case STATUS.FEEDBACK_PROVIDED:
      return {
        light: '#bde0e2',
        dark: '#2bb5c0',
      };

    case STATUS.APPROVED:
      return {
        light: '#badcc3',
        dark: '#0fc64d',
      };

    case STATUS.IN_PRODUCTION:
    case STATUS.COMPLETED:
      return {
        light: '#d6d6d6',
        dark: '#888888',
      };
    case STATUS.INCOMPLETE:
    case STATUS.CREATED:
    case STATUS.IMPORTING_ITEMS:
    case STATUS.ERRORED:
    case STATUS.ARCHIVED:
    default:
      return {
        light: 'rgb(255, 255, 255)',
        dark: 'rgb(226, 0, 122)',
      };
  }
};
