// @flow

import * as React from 'react';
import {
  FormControl,
  Select as MuiSelect,
  Typography,
  withStyles,
} from '@material-ui/core';

import type { TypeChange, TypeValidator } from 'Components/Editor/Types';
import { RenderCounter } from 'Components/RenderCounter';

type Props = {
  classes: Object,
  id: string,
  name: string,
  options: [
    {
      value: string | number,
      label: string,
    },
  ],
  label: string,
  value?: string,
  margin?: 'none' | 'normal' | 'dense',
  disabled?: boolean,
  debug?: boolean,
  onChange?: TypeChange,
  validator?: TypeValidator,
  required?: boolean,
};

type State = {
  data: ?string,
  error: ?string,
};

class Select extends React.Component<Props, State> {
  static defaultProps = {
    value: '',
    margin: 'none',
    debug: false,
    disabled: false,
    onChange: null,
    required: false,
    validator: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      data: props.value,
      error: null,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const { value } = nextProps;
    const { data } = this.state;

    if (value && nextProps.value !== data) {
      this.setState({
        data: value,
      });
    }
  }

  handleChange = (name: string, validator: ?TypeValidator) => (
    event: SyntheticInputEvent<*>,
  ) => {
    const { onChange } = this.props;
    const { value } = event.target;

    let error = null;
    if (validator) {
      error = validator(event.target.value);
    }

    this.setState({
      data: value,
      error,
    });

    if (onChange) {
      onChange(name, value);
    }
  };

  render() {
    const {
      classes,
      id,
      name,
      options,
      label,
      margin,
      disabled,
      debug,
      required,
      validator,
    } = this.props;
    const { data, error } = this.state;

    return (
      <div className={classes.root}>
        <Typography
          classes={{ root: classes.label }}
          variant="body2"
          color={disabled ? 'textSecondary' : 'inherit'}
        >
          {label} {required ? ' *' : ''}
        </Typography>

        <FormControl
          margin={margin}
          classes={{
            root: classes.controlRoot,
          }}
          fullWidth
        >
          <MuiSelect
            id={id}
            name={name}
            value={data}
            disabled={disabled}
            classes={{
              root: classes.selectRoot,
              select: classes.selectElement,
              selectMenu: classes.selectMenu,
            }}
            onChange={this.handleChange(name, validator)}
            native
            required={required}
            error={Boolean(error)}
          >
            {options &&
              options.map(option => (
                <option key={option.value} value={option.value || ''}>
                  {option.label}
                </option>
              ))}
          </MuiSelect>
        </FormControl>

        <RenderCounter isActive={debug} />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    padding: ` ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    alignItems: 'center',
  },
  label: {
    paddingRight: theme.spacing(1),
    width: '25%',
    flexShrink: 0,
  },

  controlRoot: {},
  controlFullWidth: {},
  selectRoot: {},
  selectElement: {
    '& option': {
      color: '#000',
    },
  },
  selectMenu: {},
});

export default withStyles(styles)(Select);
