// @flow
// $FlowFixMe
import 'react-app-polyfill/ie11';
// $FlowFixMe
import 'react-app-polyfill/stable';
import * as React from 'react';
import ReactDOM from 'react-dom';
import qs from 'qs';
import Router from 'Tools/Router';
import History from 'Tools/History';
import * as serviceWorker from 'Tools/serviceWorker';
import App from 'Components/App';
import { updateMeta } from 'Utils/DOMUtils';
import store from './store';

type TypeLocation = {
  key: string,
  hash: string,
  pathname: string,
  search: string,
};

const elRoot = document.getElementById('root');
const currentLocation = History.location;
const context = {
  store,
  pathname: currentLocation.pathname,
  query: qs.parse(
    currentLocation.search.substring(currentLocation.search.indexOf('?') + 1),
  ),
};
const scrollPositionsHistory = {};

const onLoad = (route: Object, location: TypeLocation) => {
  if (typeof document === 'undefined' || !route || !location) {
    return undefined;
  }

  // Update necessary tags in <head> at runtime here, ie:
  updateMeta('description', route.description);
  updateMeta('keywords', route.keywords);

  let scrollX = 0;
  let scrollY = 0;
  const pos = scrollPositionsHistory[location.key];

  if (pos) {
    scrollX = pos.scrollX;
    scrollY = pos.scrollY;
  } else {
    const targetHash = location.hash.substr(1);

    if (targetHash) {
      const target = document.getElementById(targetHash);

      if (target) {
        scrollY = window.pageYOffset + target.getBoundingClientRect().top;
      }
    }
  }

  // Restore the scroll position if it was saved into the state
  // or scroll to the given #hash anchor
  // or scroll to top of the page
  window.scrollTo(scrollX, scrollY);

  return undefined;
};

const renderApp = async (location: TypeLocation) => {
  context.pathname = location.pathname;
  context.query = qs.parse(
    location.search.substring(location.search.indexOf('?') + 1),
  );

  const route = await Router.resolve(context);

  // Prevent multiple page renders during the routing process
  if (route.redirect) {
    History.replace(route.redirect);
    return;
  }

  if (elRoot) {
    ReactDOM.render(
      <App store={store} title={route.title}>
        {route.component}
      </App>,
      elRoot,
      () => onLoad(route, location),
    );
  }
};

// Re-render the app when window.location changes
const onLocationChange = async (location: TypeLocation) => {
  await renderApp(location);
};

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
// TODO:
// - Fix the type
// $FlowFixMe
History.listen(onLocationChange);
renderApp(currentLocation);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
