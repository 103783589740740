// @flow
export type PreflightError = {
  isOpen: boolean,
  errorsChecked: number,
  errorsCount: number,
};

export type TypeActionOpen = {
  type: '@preflight/open',
};

export type TypeErrors = {
  type: '@preflight/errorsChecked',
  payload: number,
};

export type TypeErrorsCoount = {
  type: '@preflight/errorsCount',
  payload: number,
};

export type TypeResetErrorsChecked = {
  type: '@preflight/resetErrorsChecked',
};

export type TypeAction =
  | TypeActionOpen
  | TypeErrors
  | TypeResetErrorsChecked
  | TypeErrorsCoount;

const Actions = {
  open: () => ({
    type: '@preflight/open',
  }),
  errorsCount: (count: number) => ({
    type: '@preflight/errorsCount',
    payload: count,
  }),
  checkError: (check: number) => ({
    type: '@preflight/errorsChecked',
    payload: check,
  }),
  resetErrorsChecked: () => ({
    type: '@preflight/resetErrorsChecked',
  }),
};

export default Actions;
