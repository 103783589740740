// @flow

import type { TypeFormError } from './Types';
import type { TypeAction } from './Actions';

export type TypeState = ?(TypeFormError[]);

export default (state: TypeState = null, action: TypeAction): TypeState => {
  switch (action.type) {
    case '@error/add':
      return [...action.payload];

    case '@error/clear':
      return null;

    default:
      return state;
  }
};
