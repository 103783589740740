// @flow

import * as React from 'react';
import {
  FormControlLabel,
  Switch,
  FormHelperText,
  withStyles,
} from '@material-ui/core';
import type { TypeInputEventHandler } from './Types';

type Props = {
  classes: Object,
  id: string,
  name: string,
  label?: string,
  checked?: boolean,
  value?: string,
  error?: boolean,
  required?: boolean,
  helperText?: string,
  disabled?: boolean,
  onChange?: TypeInputEventHandler,
};

class BaseSwitch extends React.Component<Props> {
  static defaultProps = {
    checked: false,
    value: undefined,
    label: undefined,
    error: undefined,
    required: false,
    helperText: undefined,
    disabled: false,
    onChange: undefined,
  };

  handleChange = (event: SyntheticInputEvent<*>) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const {
      classes,
      id,
      name,
      value,
      checked,
      label,
      error,
      required,
      helperText,
      disabled,
    } = this.props;

    return (
      <React.Fragment>
        <FormControlLabel
          classes={{ root: classes.root }}
          control={
            <Switch
              id={id}
              name={name}
              checked={checked}
              value={value}
              color="primary"
              required={required}
              disabled={disabled}
              onChange={this.handleChange}
            />
          }
          label={label}
        />

        {error && <FormHelperText error>{helperText}</FormHelperText>}
      </React.Fragment>
    );
  }
}

const styles = () => ({
  root: {},
});

export default withStyles(styles)(BaseSwitch);
