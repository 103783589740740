// @flow

import type { TypeFormError } from 'Components/FormHandler';

type TypeJWTTokenError = {
  expired?: boolean,
  not_found?: boolean,
  invalid?: boolean,
};

type TypeApiError = {
  code: number,
  message: string,
  jwt_token_error?: TypeJWTTokenError,
  formErrors: TypeFormError[],
};

type TypeResponseError = (responseData: TypeApiError) => void;

type TypeResponseSuccess = (responseData: Object) => void;

export type TypeRole = 'ROLE_USER' | 'ROLE_ADMIN';
export type TypeCompany = {
  id: number,
  name: string,
};

export type TypeMe = {
  id: number,
  email: string,
  roles: TypeRole[],
  firstName: string,
  lastName: string,
  lastLogin: string,
  phone: string,
  lastLogin: string,
  company: {
    id: number,
    name: string,
    locale: string,
    canAddItems: boolean,
    canDeleteItems: boolean,
    editMode: boolean,
    hideGallery: boolean,
  },
  companies: TypeCompany[],
  type: 'User' | 'Admin',
};

type TypeActionGet = {
  type: '@me/get',
  payload: TypeMe,
};

type TypeActionGetCompanies = {
  type: '@me/get-companies',
  payload: TypeCompany[],
};

type TypeActionSetCompany = {
  type: '@me/set-company',
  payload: TypeMe,
};

type TypeActionLogin = {
  type: '@me/login',
  payload: TypeResponseSuccess,
};

type TypeActionLogout = {
  type: '@me/logout',
};

type TypeActionClear = {
  type: '@me/clear',
};

export type TypeAction =
  | TypeActionGet
  | TypeActionGetCompanies
  | TypeActionSetCompany
  | TypeActionLogin
  | TypeActionLogout
  | TypeActionClear;

const Actions = {
  get: (
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@me/get',
    meta: {
      type: 'api',
      skipSnack: true,
      url: '/me',
      onSuccess,
      onError,
    },
  }),

  getCompanies: (
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@me/get-companies',
    meta: {
      type: 'api',
      skipSnack: true,
      url: '/me/company',
      onSuccess,
      onError,
    },
  }),

  setCompany: (
    id: number,
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@me/set-company',
    meta: {
      type: 'api',
      snack: {
        title: 'Success',
        description:
          'You are now viewing information for the selected customer!',
      },
      url: '/me/company',
      method: 'PUT',
      body: JSON.stringify({ company: { id } }),
      onSuccess,
      onError,
    },
  }),

  login: (
    formData: { [name: string]: any },
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@me/login',
    meta: {
      type: 'api',
      url: '/login_check',
      method: 'POST',
      snack: {
        title: 'Welcome',
        description: 'You are logged in!',
      },
      body: JSON.stringify(formData),
      onSuccess,
      onError,
    },
  }),

  requestPasswordReset: (
    formData: { [name: string]: any },
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@me/request-password-reset',
    meta: {
      type: 'api',
      url: '/resetting/request',
      method: 'POST',
      body: JSON.stringify(formData),
      skipSnack: true,
      onSuccess,
      onError,
    },
  }),

  changePassword: (
    formData: { [name: string]: any },
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@me/change-password',
    meta: {
      type: 'api',
      url: '/resetting/reset',
      method: 'POST',
      body: JSON.stringify(formData),
      snack: {
        title: 'Reset password',
        description: 'Password successfully changed!',
      },
      onSuccess,
      onError,
    },
  }),

  logout: (
    onSuccess: ?TypeResponseSuccess = undefined,
    onError: ?TypeResponseError = undefined,
  ) => ({
    type: '@me/logout',
    meta: {
      type: 'api',
      url: '/logout',
      method: 'POST',
      snack: {
        title: 'Bye',
        description: 'You are logged out!',
      },
      onSuccess,
      onError,
    },
  }),

  clear: () => ({
    type: '@me/clear',
  }),
};

export default Actions;
