// @flow

import React from 'react';
import {
  withStyles,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@material-ui/core';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';

type Props = {
  folder: {
    id: number,
    name: string,
    seriesCount: number,
    itemsCount: number,
  },
  isList?: boolean,
  classes: Object,
  onClick: Function,
};

const FolderCard = (props: Props) => {
  FolderCard.defaultProps = {
    isList: false,
  };

  const { folder, classes, isList, onClick } = props;
  const { id, name, seriesCount, itemsCount } = folder;
  return (
    <Card classes={{ root: classes.card }}>
      <CardActionArea
        classes={{ root: classes.cardActionArea }}
        onClick={() => onClick(id)}
      >
        <CardContent classes={{ root: classes.cardContent }}>
          <div className={classes.iconContainer}>
            <FolderOpenOutlinedIcon
              classes={{ root: classes.folderIcon }}
              fontSize="large"
            />
          </div>
          <div className={classes.textContainer}>
            <Typography variant="h5" classes={{ root: classes.folderName }}>
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {`${seriesCount} series | ${itemsCount} items`}
            </Typography>
          </div>
          {isList && (
            <div className={classes.arrowIconContainer}>
              <NavigateNextOutlinedIcon classes={{ root: classes.arrowIcon }} />
            </div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
const styles = {
  card: {
    height: '125px',
  },
  cardActionArea: {
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDireciton: 'row',
    height: '100%',
    alignItems: 'center',
    position: 'relative',
  },
  iconContainer: {
    width: '66px',
    height: '66px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#b9b9b9',
    flexShrink: '0',
  },
  folderIcon: {
    color: 'white',
  },
  arrowIconContainer: {
    position: 'absolute',
    right: '0',
    border: '1px solid black',
    borderRadius: '100%',
    width: '25px',
    height: '25px',
    marginRight: '20px',
    backgroundColor: 'black',
  },
  arrowIcon: {
    color: 'white',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '20px',
  },
  folderName: {
    fontWeight: '700',
  },
};

export default withStyles(styles)(FolderCard);
