// @flow

import type { TypeAction, TypeComment, TypeMeta } from './Actions';

export type TypeState = {
  all: {
    result: TypeComment[],
    meta: ?TypeMeta,
  },
  filtered: TypeComment[],
};

const DEFAULT_STATE = {
  all: {
    result: [],
    meta: null,
  },
  filtered: [],
  frames: {},
};

export default (
  state: TypeState = DEFAULT_STATE,
  action: TypeAction,
): TypeState => {
  switch (action.type) {
    case '@comment/all': {
      const commentsOnFrame = action.payload.result.filter(
        (item: TypeComment) => item.frame !== null,
      );
      const frames = {};
      commentsOnFrame.forEach(item => {
        if (item.frame) {
          frames[item.frame] =
            item.frame in frames ? frames[item.frame] + 1 : 1;
        }
      });
      return {
        ...state,
        all: {
          ...action.payload,
        },
        filtered: action.payload.result.filter(
          (item: TypeComment) => item.frame === null,
        ),
        frames,
      };
    }

    case '@comment/filter':
      return {
        ...state,
        filtered: state.all.result.filter(
          (item: TypeComment) => item.frame === action.payload,
        ),
      };

    default:
      return state;
  }
};
